import { Spin } from 'antd';

import { BaseComponentProps } from '..';

interface PageProps extends BaseComponentProps {
  loading?: boolean;
}

export function Page(props: PageProps) {
  const { children, loading, className } = props;

  return (
    <div className={'bg-white ' + className}>
      <Spin spinning={loading ?? false}>{children}</Spin>
    </div>
  );
}
