import {RightOutlined} from '@ant-design/icons';
import {Button, Col, Row} from 'antd';
import {Slide} from 'react-awesome-reveal';
import {Link} from 'react-router-dom';

import {FoodCard} from '@/modules/Home/FoodCard';
import {SkeletonLoading} from "@/components/SkeletonLoading";

export const Kitchen = (props: any) => {
  const isMore = (
    <Link to={{pathname: '/fat-loss-recipe'}}>
      <Button
        className='mt-[15px] border-none bg-[#4A341F] bg-opacity-0 text-white hover:bg-[#4A341F] hover:bg-opacity-0 hover:text-white'>
        查看更多食谱
        <RightOutlined/>
      </Button>
    </Link>
  );
  const {KICHEN1} = props;
  return (

    <div className='flex flex-col items-center'>
      <SkeletonLoading loading={!KICHEN1}>
        <Slide triggerOnce direction='right'>
          <Row
            className='w-full w-1220px'
            flex
            justify-between
            gutter={[12, 10]}
            wrap={true}
            style={{marginLeft: '0px !important', marginRight: '0px !important'}}
          >
            {KICHEN1?.filter((i: any) => i.title != '轮播图')
              ?.slice(0, 5)
              ?.map((_: any) => (
                <Col xs={12} sm={12} md={12} lg={8} xl={8} className='overflow-hidden rounded-20px'>
                  <FoodCard title={_.title} src={_?.attachment?.[0]?.content} rate={_.star} bgUrl={_?.attachment?.[0]?.id} foodId={_?.id}/>
                </Col>
              ))}
            {KICHEN1?.filter((i: any) => i.title != '轮播图')
              ?.slice(5, 6)
              ?.map((_: any) => (
                <Col xs={12} sm={12} md={12} lg={8} xl={8} className='overflow-hidden rounded-20px'>
                  <FoodCard title={_.title} src={_?.attachment?.[0]?.content} rate={_.star} bgUrl={_?.attachment?.[0]?.id} foodId={_?.id} isMore={isMore}/>
                </Col>
              ))}
          </Row>

        </Slide>
      </SkeletonLoading>
    </div>
  );
};
