import { Button, Col, Drawer, Layout, Menu, Row } from 'antd';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuItem, findRouteWithPath } from '@web/components';
import '@/assets/global.less';
import menuImg from '@/assets/img/home/menu.svg';
import logo from '@/assets/logo.svg';
import '@/assets/navbar.less';
import { Menu as MenuList } from '@/routes';

import './index.less';

const { Header } = Layout;
const Headers = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const matchRoute = useMemo(() => findRouteWithPath(MenuList, location.pathname), [location.pathname]);
  const selectedKeys = useMemo(
    () => (matchRoute?.path || matchRoute?.key ? [matchRoute?.key ?? matchRoute.path ?? ''] : []),
    [matchRoute]
  );
  const menu = (
    <Menu mode='horizontal' selectedKeys={selectedKeys} className='flex items-center border-none'>
      {MenuList.filter((c) => !c.hidden).map((router) => {
        return <MenuItem key={router.key ?? router.path ?? router.name} route={router} title={router.name} />;
      })}
    </Menu>
  );
  return (
    <Header className='h-100px relative  bg-[#FFF1DA] align-middle'>
      <Row className='margin-398 h-full' justify='space-between' align='middle'>
        <Col span={5} className='logoPosition'>
          <img src={logo} className='logo-size' />
        </Col>
        <Col span={12} className='<md:invisible'>
          <div>{menu}</div>
        </Col>
      </Row>
      <div className='<md:visible invisible absolute right-[30px] top-0 flex h-full items-center  justify-center'>
        <img src={menuImg} className='menu-img' onClick={() => setOpen(true)} />
      </div>
      <Drawer
        title='菜单'
        placement='right'
        width={'50%'}
        onClose={() => setOpen(false)}
        open={open}
        bodyStyle={{ padding: 0 }}
      >
        <Menu mode='inline' className={'drawer-menu'} selectedKeys={selectedKeys} style={{ backgroundColor: 'white' }}>
          {MenuList.filter((c) => !c.hidden).map((router) => {
            return <MenuItem key={router.key ?? router.path ?? router.name} route={router} title={router.name} />;
          })}
        </Menu>
      </Drawer>
    </Header>
  );
};
export default Headers;
