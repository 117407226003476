export async function downloadFile(fileId: string) {
  try {
    const resp = await fetch('/sevenFresh/file/download/' + fileId);
    return resp.blob();
  } catch (error) {
    // 文件下载失败
  }
}

function blobToDataURI(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export async function downloadVideo(fileId?: string) {
  if (!fileId) {
    return '';
  }

  try {
    const resp = await fetch('/sevenFresh/file/download?fileUUID=' + fileId);
    const blob = await resp.blob();
    return blobToDataURI(blob);
  } catch (error) {
    throw new Error('视频文件下载失败');
  }
}

export async function downloadImage(fileId?: string) {
  if (!fileId) {
    return '';
  }

  try {
    const resp = await fetch('/sevenFresh/file/download?fileUUID=' + fileId);
    const blob = await resp.blob();
    return blobToDataURI(blob);
  } catch (error) {
    // 文件下载失败
  }
}
