import { ProfileOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Button, Carousel, Col, Pagination, Row, Tabs } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { NotRevalidateOption, usePagination } from '@web/common';

import { getBrandDynamics, getBrandDynamicsChild, getContent } from '@/api/about';
import back from '@/assets/img/about/back.png';
import time1 from '@/assets/img/about/time1.png';
import playImage from '@/assets/play.svg';
import { BusinessImage } from '@/components/BusinessImage';
import { BusinessVideo } from '@/components/BusinessVideo';
import { CustomContainer } from '@/components/CustomContainer';

import './index.less';
import { SkeletonLoading } from "@/components/SkeletonLoading";

const dynamicPageSize = 9;
const videoPageSize = 9;
export const BrandUpdates = () => {
  const [dynamicPage, setDynamicPage] = useState(1);
  const [videoPage, setvideoPage] = useState(1);
  const { data } = useSWR(`[About]getBrandDynamics`, () => getBrandDynamics(), NotRevalidateOption);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenOne, setIsModalOpenOne] = useState(false);
  const [activeKey, setActiveKey] = useState('DYNAMICS');
  const { data: child } = useSWR(`[About]getBrandDynamicsChild`, () => getBrandDynamicsChild(), NotRevalidateOption);
  const { page, limit, goto } = usePagination(1, -1);
  const tabClick = (key: any) => {
    setActiveKey(key);
  };
  const params = useMemo(() => {
    return {
      page: {
        pageNumber: page,
        pageSize: limit,
      },
      requestData: {
        code: activeKey ?? '',
      },
    };
  }, [page, limit, activeKey]);
  tabClick;
  const { data: content, mutate: brandUpdatesReload, isValidating: loading } = useSWR(
    activeKey ? `[ABOUT]getContent${JSON.stringify(params)}` : null,
    () => getContent(params),
    NotRevalidateOption
  );

  /* 拿到child */
  const dyBanner = child?.find((_: any) => _.code == 'DYNAMICS');
  const videoBanner = child?.find((_: any) => _.code == 'BRAND_DYNAMICS_VIDEO');
  const [videoCurrent, setVideoCurrent] = useState<any>({});
  const [dynamicCurrent, setDynamicCurrent] = useState<any>({});
  const [videos, setVideos] = useState<any>([]);
  const [dynamics, setDynamics] = useState<any>([]);
  useEffect(() => {
    const dynamic = content?.list?.filter((_: any) => _.code == 'DYNAMICS');
    const video = content?.list?.filter((_: any) => _.code == 'BRAND_DYNAMICS_VIDEO');
    setDynamics(dynamic);
    setVideos(video);
  }, [content]);

  const dynamicsCarousel = dynamics?.slice(0, 5); /* 新闻轮播 */
  const dynamicNews = dynamics?.slice(5, dynamics.length); /* 新闻分页 */

  const showModal = (_: any) => {
    setVideoCurrent(_);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalOne = (_: any) => {
    setDynamicCurrent(_);
    setIsModalOpenOne(true);
  };

  const handleCancelOne = () => {
    setIsModalOpenOne(false);
  };

  return (
    <>
      {isModalOpen && (
        /* 视频 */
        <div>
          <div className='lg:px-35 <md:py-7 reback flex cursor-pointer items-center' onClick={handleCancel}>
            <img src={back} alt='' className='mr-1 h-5 w-5' />
            <span className='text-[18px]'>返回</span>
          </div>
          <div className='lg:px-35 <md:py-7 pb-12.5 margin-398 pt-7'>
            <BusinessVideo 
              src={videoCurrent?.[0]?.content}
              alt={videoCurrent?.[0]?.fileName}
            />
          </div>
        </div>
      )}
      {isModalOpenOne && (
        /* 新闻 */
        <div>
          <div className='lg:px-35 <md:py-7 reback flex cursor-pointer items-center' onClick={handleCancelOne}>
            <img src={back} alt='' className='mr-1 h-5 w-5' />
            <span className='text-[18px]'>返回</span>
          </div>
          <div className='pb-4 text-center text-[36px] text-[#3D3D3D] pt-100px <md:pt-200px'>{dynamicCurrent?.title}</div>
          <div className='margin-398 <md:py-7 flex items-center pb-4 text-[18px] text-[#3D3D3D80]'>
            <img src={time1} alt='' className='w-4.5 h-4.5 mr-1' />
            {dynamicCurrent.date}
          </div>
          <div className='margin-398 markdown-box  <md:py-7 pt-15 pb-12.5 border-t-1 border-solid border-[#D8D8D8]'>
            <div dangerouslySetInnerHTML={{ __html: dynamicCurrent?.content }} />
          </div>
        </div>
      )}
      <div style={{ display: !isModalOpen && !isModalOpenOne ? 'block' : 'none' }}>
        <BusinessImage
          key={data?.attachment[0].id}
          src={data?.attachment[0].content}
          fileId={data?.attachment[0].id}
          alt={data?.attachment[0].fileName}
          className={'max-h-472px h-472px w-100%'}
        />
        <div className='margin-398 brandUpdates'>
          <CustomContainer title={data?.title} subTitle={data?.enTitle} subheading={undefined} />
          <div className='pt-13 '>
            <Tabs
              centered
              defaultActiveKey='DYNAMICS'
              size='large'
              onChange={(key) => tabClick(key)}
              items={[
                {
                  label: (
                    <span className='text-[30px] font-bold'>
                      <ProfileOutlined />
                      {dyBanner?.title}
                    </span>
                  ),
                  key: 'DYNAMICS',
                  children: (
                    <div>
                      <SkeletonLoading loading={loading}>
                        <Carousel autoplay className='w-[100%]'  dots={{ className: 'carousel-dots' }}>
                          {dynamicsCarousel?.map((_: any, index: any) => (
                            <div className={'border-1 h-340px'}>
                              <Row
                                onClick={() => showModalOne(_)}
                                key={index}
                                className='bg-gray-[#F4F4F4] rounded-10px px-20px py-20px flex w-[100%] items-center'
                              >
                                <Col span={8}>
                                  <BusinessImage
                                    key={_?.attachment?.[0]?.id}
                                    src={_?.attachment?.[0]?.content}
                                    fileId={_?.attachment?.[0]?.id}
                                    alt={_?.attachment?.[0]?.fileName}
                                    className={'cursor-pointer max-h-240px lg:h-240px w-450px rounded-10px'}
                                  />
                                </Col>
                                <Col span={15} push={1}>
                                  <div className=' w-[100%]'>
                                    <div className='brandTitle'>{_?.title}</div>
                                    <div className='brandTime'>{_?.date}</div>
                                    <div
                                      className='<md:leading-40px text-overflow leading-26px text-[14px] font-normal text-[#3d3d3d80]'>
                                      {_?.subheading}
                                    </div>
                                    <Button
                                      className='detail mt-20px hover:z-200 <lg:w-15 <md:flex  <md:flex-center <md:h-6 border-1  px-5px py-5px mt-[15px] bg-[#fff]
                                    text-[#3d3d3d80]'
                                      onClick={() => showModalOne(_)}
                                    >
                                      {`了解详情 >`}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </Carousel>
                      </SkeletonLoading>

                      <Row gutter={[12, 12]} className='<md:py-140px pt-80px flex '>
                        <SkeletonLoading loading={loading}>
                          {dynamicNews
                            ?.slice((dynamicPage - 1) * dynamicPageSize, dynamicPage * dynamicPageSize)
                            ?.map((_: any) => (
                              <Col xs={24} sm={24} md={12} lg={8} xl={8}  onClick={() => showModalOne(_)} className={'cursor-pointer'}>
                                <div className='row1 rounded-b-10px '>
                                  <div className='bg-white'>
                                    <BusinessImage
                                      key={_?.attachment?.[0]?.id}
                                      src={_?.attachment?.[0]?.content}
                                      fileId={_?.attachment?.[0]?.id}
                                      alt={_?.attachment?.[0]?.fileName}
                                      onClick={() => showModalOne(_)}
                                      className={'md:max-h-240px rounded-t-10px lg:h-240px w-100%'}
                                    />
                                  </div>

                                  <div
                                    className='w-[100%] border-1 rounded-b-10px border-t-0 border-solid border-[#D8D8D8] p-5'>
                                    <div className='brandTitle'>{_?.title}</div>
                                    <div className='brandTime'>{_?.date}</div>
                                    <div
                                      className='<md:leading-60px text-overflow text-[14px] font-normal leading-[26px] text-[#3d3d3d80]'
                                      style={{ height: '60px' }}
                                    >
                                      {_?.subheading}
                                    </div>
                                    <Button
                                      className='detail hover:z-200 border-1 <lg:w-15 <md:flex  <md:flex-center <md:h-6 px-5px py-5px mt-[15px] bg-[#fff]
                                    text-[#3d3d3d80]'
                                      onClick={() => showModalOne(_)}
                                    >
                                      {`了解详情 >`}
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            ))}
                        </SkeletonLoading>
                      </Row>
                      <div className=' mt-17 flex justify-center'>
                        <Pagination
                          total={dynamicNews?.length ?? 0}
                          defaultPageSize={dynamicPageSize}
                          current={dynamicPage}
                          onChange={(page) => {
                            setDynamicPage(page);
                          }}
                        />
                      </div>
                    </div>
                  ),
                },
                {
                  label: (
                    <span className=' text-[30px] font-bold'>
                      <VideoCameraOutlined />
                      {videoBanner?.title}
                    </span>
                  ),
                  key: 'BRAND_DYNAMICS_VIDEO',
                  children: (
                    <div>
                      <Row gutter={[12, 12]} className='<md:py-7 flex pt-10'>
                        <SkeletonLoading loading={loading}>
                          {videos?.slice((videoPage - 1) * videoPageSize, videoPage * videoPageSize)?.map((_: any) => (
                            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                              <div onClick={() => showModal(_?.attachment)} className='video-img cursor-pointer'>
                                <img className={'play-img'} src={playImage} alt='' />
                                <BusinessImage
                                  key={_?.attachment?.find((i: any) => i.type == 'png')?.id}
                                  src={_?.attachment?.find((i: any) => i.type == 'png')?.content}
                                  fileId={_?.attachment?.find((i: any) => i.type == 'png')?.id}
                                  alt={_?.attachment?.find((i: any) => i.type == 'png')?.fileName}
                                  className={'h-219px max-h-219px w-100%'}
                                  skeletonClass={'h-220px'}
                                  showLogoSkeleton={true}
                                />
                              </div>
                            </Col>
                          ))}
                        </SkeletonLoading>

                      </Row>
                      <div className='mt-17 flex justify-center'>
                        <Pagination
                          // hideOnSinglePage={true}
                          total={videos?.length ?? 0}
                          pageSize={videoPageSize}
                          current={videoPage}
                          onChange={(page) => {
                            setvideoPage(page);
                          }}
                        />
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
