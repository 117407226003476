import Api from '@/constant/request';
import { UniverseData } from '@/interface/universe';

export async function getKitchenInfo() {
  const resp = await Api.post('/api/kichen/info');
  return resp.data;
}

export async function getKitchenChildren(): Promise<UniverseData[]> {
  const resp = await Api.post('/api/kichen/child');
  return resp.data;
}
