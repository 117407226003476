import { Button, Rate } from 'antd';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import foodImg from '@/assets/img/home/food.png';
import { BusinessImage } from '@/components/BusinessImage';
import { Path } from '@/routes';

import './index.less';

export interface FoodCardProps {
  title: ReactNode;
  foodId: string | number;
  rate?: number;
  bgUrl?: string;
  isMore?: any;
}

export const FoodCard = (props: any) => {
  const navigate = useNavigate();

  const enterDetail = () => {
    navigate({
      pathname: Path.recipeDetail(),
      search: `recipe=${props.foodId}`,
    });
  };

  const renderImg = () => {
    if (props.bgUrl) {
      return <BusinessImage src={props.src} fileId={props.bgUrl} onClick={enterDetail} className={'cursor-pointer h-292px w-100%'} />;
    }

    return <img src={foodImg} alt={props.title} />;
  };

  return (
    <div
      className='foodCard flex  transform flex-col-reverse rounded-lg hover:-translate-y-1 hover:scale-105 hover:overflow-hidden'
    >
      {renderImg()}
      <div
        className='<md:h-150px food-mask h-[94px] rounded-b-10px bg-[#4A341F] bg-opacity-60 p-[15px]'
        style={{ width: '100%' }}
      >
        <div className='flex justify-between'>
          <span className='text- <lg:text[18px] <md:text[16px] text-[20px] font-bold text-white'>{props.title}</span>
          <Rate allowHalf className='text-[#FFC400]' disabled defaultValue={props.rate ?? 2} />
        </div>
        <div className='flex items-center justify-between'>
          <Button
            className='detail hover:z-200 mt-[15px]
          rounded-[172px]
          bg-[#4A341F]
          bg-opacity-30
          text-white hover:relative hover:border-white hover:bg-white hover:bg-opacity-100 hover:text-[#FFAA00]'
            onClick={enterDetail}
          >
            了解详情
          </Button>
          <div className='more'>{props.isMore}</div>
        </div>
      </div>
    </div>
  );
};
