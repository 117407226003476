import { Col, Row } from 'antd';
import { useMemo } from 'react';
import Slider from 'react-slick';
import useSWR from 'swr';

import { NotRevalidateOption, usePagination } from '@web/common';
import { Page } from '@web/components';
import { getAbout, getAboutChild, getContent } from '@/api/about';
import left from '@/assets/img/about/left.png';
import right from '@/assets/img/about/right.png';
import { BusinessImage } from '@/components/BusinessImage';
import { BusinessVideo } from '@/components/BusinessVideo';
import { CustomContainer } from '@/components/CustomContainer';
import { CustomContainerLeft } from '@/components/CustomContainerLeft';

import './index.less';
import { SkeletonLoading } from "@/components/SkeletonLoading";

export const About = () => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img src={right} />,
    prevArrow: <img src={left} />,
    cssEase: 'ease-in-out',
  };

  const imageSetting = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'ease-in-out',
    arrows: false,
  };
  const { data: child } = useSWR(`[About]getAboutChild`, () => getAboutChild(), NotRevalidateOption);
  const { data: aboutData } = useSWR(`[About]getAbout`, () => getAbout(), NotRevalidateOption);

  /* 获取child */
  const dynamic = child?.find((_: any) => _.code === 'PROFILE');
  const video = child?.find((_: any) => _.code === 'HISTORY');

  return (
    <Page className={'margin-398'}>
      <BusinessVideo
        key={aboutData?.attachment?.[0]?.id}
        src={aboutData?.attachment?.[0]?.content}
        alt={aboutData?.attachment?.[0]?.fileName}
        width={'100%'}
        height={360}
      />
      <SkeletonLoading loading={!dynamic} className={'mt-20px'}>
        <div className={'mt-[50px]'}>
          <Row gutter={[12, 12]} flex className='about-box' style={{ width: '100%' }}>
            <Col span={7}>
              <Slider {...imageSetting}>
                {dynamic?.attachment.map((item: any) => (
                  <div className={'flex items-center h-270px'}>
                    <BusinessImage key={item.id} src={item.content} fileId={item.id} alt={item.fileName} className={'about-image'} />
                  </div>
                ))}
              </Slider>
            </Col>

            <Col span={13} push={1}>
              <CustomContainerLeft title={dynamic?.title} subTitle={dynamic?.enTitle} />
              <div
                className='<md:line-clamp-2 <md:leading-60px text-[18px]  font-normal leading-[32px] text-[#3d3d3d80]'>
                {dynamic?.copywriting}
              </div>
            </Col>

          </Row>
        </div>
      </SkeletonLoading>

      <CustomContainer title={video?.title} subTitle={video?.enTitle} subheading={video?.subheading} />
      <SkeletonLoading loading={!video}>
        <div className='aboutSlider'>
          <Slider {...settings}>
            {video?.attachment.map((item: any) => (
              <div className='foodImg slider_item'>
                <BusinessImage key={item.id} src={item.content} fileId={item.id} alt={item.fileName} className={'w-100% max-h-936px'} showLogoSkeleton />
              </div>
            ))}
          </Slider>
        </div>
      </SkeletonLoading>
    </Page>
  );
};
