import { Button, Rate } from 'antd';
import { useNavigate } from 'react-router-dom';
import foodImg from '@/assets/img/home/food.png';
import { BusinessImage } from '@/components/BusinessImage';

import './index.less';

export interface FoodCardProps {
  title: string;
  foodId: string | number;
  rate?: number;
  bgUrl?: string;
  src?: string;
}

export const FoodCard = (props: FoodCardProps) => {
  const navigate = useNavigate();

  const enterDetail = () => {
    navigate({
      pathname: `/recipe-detail`,
      search: `recipe=${props.foodId}`,
    });
  };

  const renderImg = () => {
    if (props.bgUrl) {
      return <BusinessImage
        fileId={props.bgUrl}
        src={props.src}
        onClick={enterDetail}
        className={'cursor-pointer <md:h-500px max-h-200px h-200px w-100% rounded-t-5px'}/>;
    }

    return <img src={foodImg} alt={props.title} />;
  };

  return (
    <div className='border-1 rounded border-solid border-[#D8D8D8]'>
      {renderImg()}
      <div className='h-30 foodBtnBoxFlex md:h-26 rounded-b-lg bg-white bg-opacity-60 p-5'>
        <div className='flex items-center justify-between'>
          <span className='text-[28px] font-bold text-[#3D3D3D] md:text-[16px]'>{props.title}</span>
          <Rate allowHalf className='text-[#FFC400] md:text-[18px]' disabled defaultValue={props.rate ?? 0} />
        </div>
        <Button
          className='detail hover:z-200 border-1 <lg:w-15 <md:flex  <md:flex-center <md:h-6 px-5px py-5px mt-[15px] rounded-[172px] bg-[#fff]
                                    text-[#3d3d3d80]'
          onClick={enterDetail}
        >
          {`了解详情 >`}
        </Button>
      </div>
    </div>
  );
};
