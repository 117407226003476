
export const PDF1 = () => {

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src="https://img10.360buyimg.com/imagetools/jfs/t1/227638/8/23606/764719/668e7212F97d0d38f/1bf80e030d32c9d6.png"
        alt=""
        style={{ width: 'auto', height: '800px' ,marginTop:'50px'}}
      />
    </div>
  );
};
