import { Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { NotRevalidateOption, useSimpleJsonQuery } from '@web/common';
import { getContentDetail } from '@/api/about';
import back from '@/assets/img/about/back.png';
import { BusinessImage } from '@/components/BusinessImage';

import './index.less';
import {SkeletonLoading} from "@/components/SkeletonLoading";

export function RecipeDetail() {
  const navigate = useNavigate();

  const { value: query } = useSimpleJsonQuery({
    recipe: '',
  });

  const { data: recipeDetail, isValidating: loading } = useSWR(
    query.recipe ? `[Recipe]getContentDetail/${query.recipe}` : null,
    () => getContentDetail({ requestData: { id: query.recipe } }),
    NotRevalidateOption
  );

  return (
    <div className='bigBox'>
      <div className='lg:px-35 <md:py-7 reback flex cursor-pointer items-center' onClick={() => navigate(-1)}>
        <img src={back} alt='' className='mr-1 <md:h-3 <md:w-3 h-20px w-20px' />
        <span className='text-[18px]'>返回</span>
      </div>
      {/* <div className='pb-4 text-center text-[36px] text-[#3D3D3D]'>{recipeDetail?.title}</div> */}
      <div
        className='lg:px-35 <md:py-7 pb-12.5 flex'
        style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}
      >
        {loading ? (
          <SkeletonLoading className={' margin-398'}  loading={loading} style={{ marginTop: '100px'}}/>
        ) : (
          <div className={'margin-398'} style={{ width: '100%', marginTop: '50px' }}>
            <BusinessImage showLogoSkeleton={true} src={recipeDetail?.attachment?.[1].content} fileId={recipeDetail?.attachment?.[1].id} preview={false} />
          </div>
        )}
      </div>
    </div>
  );
}
