import { ReactNode } from 'react';
import { Slide } from 'react-awesome-reveal';

import { BaseComponentProps } from '@web/components';

export interface CustomContainerProps extends BaseComponentProps {
  title: ReactNode;
  subTitle: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  align?: 'left' | 'center' | 'right';
}
export const CustomContainerLeft = (props: CustomContainerProps) => {
  return (
    <Slide triggerOnce direction='right'>
      <div className={props.className}>
        <div className=' flex flex-col items-start '>
          <div className='py-10px'>
            <span className='size-36 text-left font-bold uppercase text-[#D0a984] text-opacity-25'>
              {props.subTitle}
            </span>
          </div>
          <div className='flex items-center'>
            <span className='size-36 text-left font-bold text-[#D0A984]'>{props.title}</span>
          </div>
          <div className='mt-20px mb-40px h-4px w-56px bg-[#D0A984] font-bold' />
          {props.description && (
            <div className='mt-20px flex items-center justify-center'>
              <span className='<md:text-lg w-[52%]  text-left text-2xl font-normal text-[#3D3D3D] text-opacity-50'>
                {props.description}
              </span>
            </div>
          )}
        </div>
        {props.children}
      </div>
    </Slide>
  );
};
