import Api from '@/constant/request';
import { CityListType } from '@/interface/city';
import { PageRequestType } from '@/interface/page';
import { ShopListType } from '@/interface/shop';

export async function getCityList(data: PageRequestType): Promise<CityListType> {
  const resp = await Api.post<CityListType>('/api/city/list', data);
  return resp.data;
}

export async function getShopList(data: PageRequestType): Promise<ShopListType> {
  const resp = await Api.post<ShopListType>('/api/store/list', data);
  return resp.data;
}

export async function getShopTypeList() {
  const resp = await Api.post('/api/store/findTypeList');
  return resp.data;
}

export async function getShopInfoById(data: any) {
  const resp = await Api.post('/api/store/info', data);
  return resp.data;
}

export async function getLiving() {
  const resp = await Api.post('/api/foodShop/getLiving');
  return resp.data;
}

export async function getSupermarket() {
  const resp = await Api.post('/api/foodShop/getSupermarket');
  return resp.data;
}

export async function download(data: any) {
  return await Api.get(`/file/download?fileUUID=${data}`);
}

export async function getGoodFoodInfo() {
  const resp = await Api.post('/api/foodShop/getMuji');
  return resp.data;
}
