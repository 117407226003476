import { notification } from 'antd';
import axios, { AxiosError } from 'axios';

const Api = axios.create({
  baseURL: '/sevenFresh',
  withCredentials: true,
  timeout: 15000,
});

Api.interceptors.request.use((config) => {
  config.headers = {
    'Content-Type': 'application/json;charset=utf-8',
  };
  return config;
});

Api.interceptors.response.use(
  (resp) => {
    const { code, message } = resp.data;
    if (code !== '0') {
      notification.error({ message: `${message && message.length > 100 ? `${message.slice(0, 100)}...` : message}` });

      return Promise.reject(new Error(message));
    }
    return resp.data;
  },
  (error: AxiosError<any>) => {
    return Promise.reject(error);
  }
);

export default Api;
