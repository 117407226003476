import { CaretRightOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Collapse, Input, Row, Select, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { NotRevalidateOption } from '@web/common';
import { Page } from '@web/components';
import { getRecruitList } from '@/api/recruit';
import { getCityList } from '@/api/shop';
import back from '@/assets/img/about/back.png';
import locationImg from '@/assets/img/recruit/location.png';
import { BusinessImage } from '@/components/BusinessImage';
import './index.less';
import { useRecruitType } from './useRecruitType';

export const RecruitDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [recruitType, headerImg] = useRecruitType();
  const [newList, setNewList] = useState([]);
  const [name, setName] = useState('');
  const [cityId, setCityId] = useState('');

  // 城市列表数据
  const cityParams = useMemo(
    () => ({
      page: { pageNumber: 1, pageSize: 500 },
      requestData: { isRecruit: true },
    }),
    []
  );
  const { data: cityList, isValidating: cityLoading } = useSWR(
    `[Recruit]getCityList/${JSON.stringify(cityParams)}`,
    () => getCityList(cityParams),
    NotRevalidateOption
  );

  const {
    data: recruitData,
    isValidating: jobLoading,
    mutate: recruitListReload,
  } = useSWR(
    `[Recruit]getRecruitList/${JSON.stringify({
      cityId: cityId,
      name: name,
      typeId: recruitType?.id,
    })}`,
    () => {
      return getRecruitList({
        page: { pageNumber: 1, pageSize: 999 },
        requestData: {
          name: name,
          cityId: cityId,
          typeId: recruitType?.id,
        },
      });
    },
    NotRevalidateOption
  );
  useEffect(() => {
    setNewList(recruitData?.list);
  }, [recruitData]);

  const handleRecruitNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    const list = recruitData?.list.filter((item: any) => {
      return item.name.includes(event.target.value);
    });
    setNewList(list);
  };

  const renderEmptyList = () => {
    if (recruitData?.list && recruitData?.list.length === 0) {
      return <div className='recruit-detail-list-empty'>暂无数据</div>;
    }

    return null;
  };

  const renderCollapsePaneHeader = (item: any) => (
    <div className='recruit-detail-list-item-header'>
      <span className='job-name'>{item.name}</span>
      <span style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <img alt='location' src={locationImg} />
        {item.cityName}
      </span>
    </div>
  );

  useEffect(() => {
    recruitListReload();
  }, [location.search]);

  return (
    <Page>
      {headerImg && (
        <BusinessImage
          key={recruitType?.attachment?.[0]?.id}
          src={recruitType?.attachment?.[0]?.content}
          fileId={recruitType?.attachment?.[0]?.id}
          alt={recruitType?.attachment?.[0]?.fileName}
          className={'max-h-472px h-472px w-100%'}
        />
      )}
      {/*返回按钮*/}
      <div className={'margin-398'}>
        <div className='w-150px <md:py-70px pt-50px flex cursor-pointer items-center' onClick={() => navigate('/job')}>
          <img src={back} alt='' className='mr-1 h-4 w-4' />
          <span className='text-[18px]'>返回</span>
        </div>

        <h3 className='recruit-detail-header mt-20px border-t-10px border-solid border-[#dbc8b6]'>
          <div className='recruit-detail-header-zh'>{recruitType?.title}</div>
          <div className='recruit-detail-header-en'>{recruitType?.enTitle}</div>
        </h3>
        <Row className='flex' gutter={[12, 12]}>
          <Col span={12}>
            <Select
              style={{ width: '100%' }}
              placeholder='请选择城市'
              loading={cityLoading}
              options={cityList?.list?.map((item) => ({ label: item.name, value: item.id }))}
              onChange={(value) => {
                setCityId(value);
              }}
            />
          </Col>
          <Col span={12}>
            <Input
              allowClear
              placeholder='请输入职位名称'
              suffix={
                <a role='button'>
                  <SearchOutlined
                    onClick={() => {
                      handleRecruitNameChange;
                    }}
                  />
                </a>
              }
              value={name}
              onChange={handleRecruitNameChange}
              onPressEnter={() => recruitListReload()}
            />
          </Col>
        </Row>
        <Spin spinning={jobLoading} wrapperClassName='recruit-detail-list-wrapper'>
          {renderEmptyList()}
          <Collapse
            ghost
            className='recruit-detail-list'
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : -90} />}
          >
            {newList?.map((item: any) => (
              <Collapse.Panel key={item.id} header={renderCollapsePaneHeader(item)}>
                <p className='description'>{item.content}</p>
                <p className='contact-info'>
                  {item.contactNumber && <span>联系 HR：{item.contactNumber}</span>}
                  {item.mail && <span>简历投递至：{item.mail}</span>}
                </p>
              </Collapse.Panel>
            ))}
          </Collapse>
        </Spin>
      </div>
    </Page>
  );
};
