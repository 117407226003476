import classNames from 'classnames';
import { ReactNode } from 'react';

import { BaseComponentProps } from '@web/components';

export interface CustomContainerProps extends BaseComponentProps {
  title: ReactNode;
  subTitle: ReactNode;
  subheading?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  align?: 'left' | 'center' | 'right';
  subClassName?: string;
}
export const CustomContainer = (props: CustomContainerProps) => {
  return (
    <div className={classNames('size-36', 'margin-398', props.className)}>
      <div className={classNames('subtitle-margin', props.subClassName)}>
        <div className='flex items-center justify-center'>
          <span className='<text-center h-[30px] font-bold uppercase text-[#D0a984] text-opacity-25'>
            {props.subTitle}
          </span>
        </div>
        <div className='mb-10px flex h-[30px] items-center justify-center'>
          <span className='< size-36 text-center font-bold text-[#D0A984]'>{props.title}</span>
        </div>
        {props.subheading && (
          <div className='flex h-[40px] items-center justify-center'>
            <span className='< font-bold-[400] font-24 text-center text-[#D0A984]'>{props.subheading}</span>
          </div>
        )}
        {props.description && (
          <div className='mt-40px mb-10px flex flex-col items-center'>
            <span
              style={{ lineHeight: 1.5 }}
              className='whitespace-pre-wrap text-center text-[18px] font-normal text-[#3d3d3d80]'
            >
              {props.description}
            </span>
          </div>
        )}
      </div>
      {props.children}
    </div>
  );
};
