import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { NotRevalidateOption } from '@web/common';

import { getChild } from '@/api/recruit';

interface RecruitTypeItem {
  id: string;
  title: string;
  enTitle: string;
  subheading: string | null;
  copywriting: string | null;
  code: string;
  category: number;
  attachment: any[] | null;
  status: number;
  createdBy: string | null;
  createdTime: string | null;
  updatedBy: string | null;
  updatedTime: string | null;
}

function getQuery(path: string) {
  const query = path.split('?');
  const ret = query
    .filter((item) => item)
    .map((item) => item.split('='))
    .reduce<any>((ret: any, [key, value]: any[]) => {
      ret[key] = value;
      return ret;
    }, {});
  return ret;
}

export function useRecruitType(): [RecruitTypeItem | undefined, string | undefined] {
  const location = useLocation();
  const [headerImg, setHeaderImg] = useState<string>();
  const [recruitType, setRecruitType] = useState<RecruitTypeItem | undefined>();
  const { data: recruitTypeList } = useSWR(`[Recruit]getChild`, getChild, NotRevalidateOption);

  useEffect(() => {
    if (recruitTypeList) {
      const query = getQuery(location.search);
      const target: RecruitTypeItem = recruitTypeList.find((item: RecruitTypeItem) => item.code.includes(query.code));

      if (target) {
        setHeaderImg(target.attachment?.[0]?.id);
        setRecruitType(target);
      }
    }
  }, [recruitTypeList, location.search]);

  return [recruitType, headerImg];
}
