import { Col, Divider, Menu, Pagination, Row, Skeleton, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { NotRevalidateOption} from '@web/common';
import { Page } from '@web/components';

import { getContent } from '@/api/about';
import { getFeaturedChildren } from '@/api/featured';
import { BusinessImage } from '@/components/BusinessImage';

import './index.less';
import { SkeletonLoading } from "@/components/SkeletonLoading";

export const CarefullyChosen = () => {

  const { data: childrenData } = useSWR(`[Featured]getFeaturedChildren`, getFeaturedChildren, NotRevalidateOption);
  const [code, setCode] = useState('');
  const [activeTab, setActiveTab] = useState<any>({});
  const [page, setPage] = useState(1);
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const key = search.get('key');
  useEffect(() => {
    if (key && childrenData) {
      setCode(key);
      const target = childrenData.find((item: any) => item.code === key);
      setActiveTab(target);
    }
  }, [key]);
  const limit = 8;
  useEffect(() => {
    if (childrenData) {
      setCode(childrenData[0].code);
      // 设置初始tab
      setActiveTab(childrenData[0]);
    }
  }, [childrenData]);

  // 设置featureList参数
  const params = useMemo(() => {
    return {
      page: {
        pageNumber: page,
        pageSize: limit,
      },
      requestData: {
        code: code,
      },
    };
  }, [page, limit, code]);

  const { data: fList } = useSWR(
    `[ABOUT]getContent${JSON.stringify(params)}`,
    code ? () => getContent(params) : null,
    NotRevalidateOption
  );

  return (
    <Page>
      <BusinessImage
        className={'imgFlexs max-h-472px h-472px w-100%'}
        key={activeTab?.attachment?.[0]?.id}
        src={activeTab?.attachment?.[0]?.content}
        fileId={activeTab?.attachment?.[0]?.id}
        alt={activeTab?.attachment?.[0]?.fileName}
        width={'100%'}
      />

      {/* Tabs*/}

      <div className='<md:py-7 margin-398 py-20'>
        <div className='tabCity flex cursor-pointer flex-wrap justify-center'>
          {childrenData?.map((_) => (
            <div
              key={_.id}
              className='tabCityItem mr-[40px] mt-[10px] border-b-[2px] border-solid pb-[31px]'
              style={{
                color: activeTab.title == _.title ? '#D0A984' : '#D8D8D8',
                borderBottomWidth: activeTab.title == _.title ? '2px' : '0px',
                borderBottomColor: activeTab.title == _.title ? '#D0A984' : '#D8D8D8',
              }}
              onClick={() => {
                setActiveTab(_);
                setCode(_.code);
                setPage(1);
              }}
            >
              <BusinessImage
                key={activeTab.title == _.title ? _.activeIcon[0].id : _.icon[0].id}
                src={activeTab.title == _.title ? _.activeIcon[0].content : _.icon[0].content}
                fileId={activeTab.title == _.title ? _.activeIcon[0].id : _.icon[0].id}
                alt=''
                className='w-30px h-30px'
                style={{ margin: 'auto',background: "transparent" }}
              />
              <div
                className='font-500 careSize text-[24px]'
                style={{ color: activeTab.title == _.title ? '#D0A984' : '#3d3d3d' }}
              >
                {_.title}
              </div>
            </div>
          ))}
        </div>
        {/*图片内容对应区域*/}
        <SkeletonLoading loading={!fList} className={'mt-30px'}>
          <div className='<md:py-7 flex flex-wrap justify-around pt-[68px]'>
            <Row flex wrap={true} gutter={[12, 12]} justify={'space-between'} style={{ width: '100%' }}>
              {fList?.list.map((item: any, index: any) => (
                <Col key={item.id} span={12}>
                  <div
                    className={
                      'border-1 h-437px rounded-6px flex flex-auto justify-between border-solid border-[#D0A984] '
                    }
                  >
                    <div className={'content pt-40px px-10px flex flex-auto flex-col justify-between'}>
                      <BusinessImage
                        key={item.attachment[0].id}
                        src={item.attachment[0].content}
                        fileId={item.attachment[0].id}
                        alt={item.attachment[0].fileName}
                        width={'auto'}
                        className='good-img-l bg-[#fffff]'
                      />
                      <div className={'px-10px max-w-300px'}>
                        <div className={'truncate text-[18px] font-bold'}>{item.title}</div>
                        <div className={'font-400 pt-10px pb-20px truncate text-[18px]'}>{item.subheading}</div>
                      </div>
                    </div>
                    <div className={'good-img-box'}>
                      <BusinessImage
                        key={item.attachment[1].id}
                        src={item.attachment[1].content}
                        fileId={item.attachment[1].id}
                        alt={item.attachment[1].fileName}
                        width={'100%'}
                        className='good-img-r bg-[#fffff]'
                      />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </SkeletonLoading>

        <div className='mt-17 flex justify-center'>
          <Pagination
            current={page}
            total={fList?.total}
            pageSize={8}
            onChange={(page) => {
              setPage(page);
            }}
          />
        </div>
      </div>
    </Page>
  );
};
