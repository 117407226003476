import Api from '@/constant/request';

export async function getAbout() {
  const resp = await Api.post('/api/about/getAbout');
  return resp.data;
}

export async function getContent(params: any) {
  const resp = await Api.post('/api/content/list', params);
  return resp.data;
}

export async function getContentDetail(data: any) {
  const resp = await Api.post('/api/content/info', data);
  return resp.data;
}

export async function getBrandDynamics() {
  const resp = await Api.post('/api/about/getBrandDynamics');
  return resp.data;
}

export async function getPrivateLabel() {
  const resp = await Api.post('/api/about/getPrivateLabel');
  return resp.data;
}

export async function getMenuList() {
  const resp = await Api.post('/api/type/menuList');
  return resp.data;
}

export async function getAboutChild() {
  const resp = await Api.post('/api/about/getAboutChild');
  return resp.data;
}

export async function getPrivateLabelChild() {
  const resp = await Api.post('/api/about/getPrivateLabelChild');
  return resp.data;
}

export async function getBrandDynamicsChild() {
  const resp = await Api.post('/api/about/getBrandDynamicsChild');
  return resp.data;
}
