import {memo, ReactNode} from "react";
import {Spin} from 'antd';
import bgLoading from "@/assets/bgLoading.png";


interface SkeletonLoadingProps {
  children?: ReactNode,
  loading: boolean
  style?: {}
  className?: string
}

export const SkeletonLoading = memo(function ({children, ...props}: SkeletonLoadingProps) {

  return (
    <>
      {
        props.loading
          ? <div
            className={` flex justify-center items-center mb-20px h-520px ${props.className}`}
            style={{
              ...props.style,
              width: '100%',
              background: `url(${bgLoading}) center center /cover`
            }}>
            <Spin spinning={props.loading} size={"large"}/>
          </div>
          : children
      }
    </>

  );
})





