import Api from '@/constant/request';
import { PageRequestType } from '@/interface/page';

export async function getRecruitTypeList() {
  const resp = await Api.post('/api/recruit/findTypeList');
  return resp.data;
}

export async function getRecruitList(data: PageRequestType) {
  const resp = await Api.post('/api/recruit/list', data);
  return resp.data;
}

export async function getJoinInfo() {
  const resp = await Api.post('/api/join/info');
  return resp.data;
}

export async function getChild() {
  const resp = await Api.post('/api/join/child');
  return resp.data;
}
