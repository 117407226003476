import { useRef } from 'react';
import { Player } from 'video-react';

// import { downloadFile, downloadImage, downloadVideo } from '@/api/file';

import './index.less';

export const BusinessVideo = ({ fileId, ...props }: any) => {
  const videoRef = useRef(null);
  // const getImageBase64 = async () => {
  //   const base64 = await downloadVideo(fileId);
  //   base64 && setImgBase64(base64);
  // };
  // console.log(fileId);

  // useEffect(() => {
  //   getImageBase64();
  // }, []);

  // const bofang = () => {
  //   if (videoRef?.current?.paused) {
  //     videoRef?.current?.play();
  //   } else {
  //     videoRef?.current?.pause();
  //   }
  // };

  return (
    <div>
      {/* <video ref={videoRef} {...props} alt='' loop="loop">
        <source
          src={`http://localhost:3000/sevenFresh/file/download?fileUUID=4db56bd60abd49859bedd27e4b5e6cb8`}
        ></source>
        您的浏览器不支持 HTML5 video 标签。
      </video>
      <button onclick={bofang}>点击播放/暂停</button> */}
      <Player 
        ref={videoRef}
        className='playerbox'
        videoId='video-1'
        preload='auto'
        {...props} 
      />
    </div>
  );
};
