import { Col, Row } from 'antd';
import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { NotRevalidateOption, usePagination } from '@web/common';
import { Page } from '@web/components';
import { getContent } from '@/api/about';
import { getGoodFoodInfo } from '@/api/shop';
import { BusinessImage } from '@/components/BusinessImage';
import { UniverseData } from '@/interface/universe';
import { useNavigate } from 'react-router-dom';
import { SkeletonLoading } from "@/components/SkeletonLoading";
export const GoodProduct = () => {
  const { data } = useSWR(`[GoodFood]getGoodFoodInfo`, getGoodFoodInfo, NotRevalidateOption);
  const goodFood = data as UniverseData;
  const [activeKey] = useState('MUJI');
  const { page, limit } = usePagination(1, -1);
  const params = useMemo(() => {
    return {
      page: {
        pageNumber: page,
        pageSize: limit,
      },
      requestData: {
        code: activeKey ?? '',
      },
    };
  }, [page, limit, activeKey]);

  const { data: content, isValidating: loading } = useSWR(
    activeKey ? `[ABOUT]getContent${JSON.stringify(params)}` : null,
    () => getContent(params),
    NotRevalidateOption
  );
  const navigate = useNavigate();
  /* 七鲜超市 */
  const gotoSupermarket = () => {
    navigate('/super-market', { replace: true });
  };
  /* 七鲜生活 */
  const gotoLife = () => {
    navigate('/life', { replace: true });
  };
  /* 七鲜良品 */
  const gotoGoodProduct = () => {
    navigate('/good-product', { replace: true });
  };
  return (
    <Page>
      <BusinessImage
        key={goodFood?.attachment[0].id}
        src={goodFood?.attachment[0].content}
        fileId={goodFood?.attachment[0].id}
        alt={goodFood?.attachment[0].fileName}
        width={'100%'}
        className={'max-h-472px h-472px w-100%'}

      />
      <div className='<md:py-56px py-50px margin-398'>
        {/* <CustomContainer title={goodFood?.title} subTitle={goodFood?.enTitle} /> */}
        {/* 标题 */}
        <div className='titles flex justify-around items-center mt-[80px] pr-[200px] pl-[200px] border-b border-solid border-[#e5e7eb]'>
          <div className='text-[30px] font-bold mb-[20px] text-[#3d3d3d80] cursor-pointer' onClick={gotoSupermarket}>七鲜超市</div>
          <div className='text-[30px] font-bold mb-[20px] text-[#3d3d3d80] cursor-pointer' onClick={gotoLife}>七鲜生活</div>
          <div className='text-[35px] font-bold pb-[20px] text-[#D0A984] border-b-2 border-solid border-[#D0A984] cursor-pointer' onClick={gotoGoodProduct}>七鲜良品</div>
        </div>
        <div className=' mt-40px flex justify-center'>
          <p
            className='w-862px mx-15 <md:leading-60px line-clamp-4 text-justify text-[18px] font-normal leading-[32px] text-[#3d3d3d80]'>
            {goodFood?.copywriting}
          </p>
        </div>
      </div>
      <Row className='margin-398' justify={'space-between'} gutter={[12, 12]}>
        <SkeletonLoading loading={loading} >
          {content?.list?.slice(0, 9).map((item: any) => (
            <Col key={item.id} className={'padding-0'} lg={8} md={12} xs={24}>
              <BusinessImage
                key={item.attachment[0].id}
                src={item.attachment[0].content}
                fileId={item.attachment[0].id}
                alt={item.attachment[0].id}
                className={'lg:max-h-450px lg:h-450px lg:w-450px <md:h-75 <md:w-75'}
              />
            </Col>
          ))}
        </SkeletonLoading>
      </Row>
    </Page>
  );
};
