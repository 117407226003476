import {Carousel, Tabs} from 'antd';
import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import useSWR from 'swr';

import {NotRevalidateOption} from '@web/common';
import {Page, findRouteWithPath} from '@web/components';

import {getKitchenChildren, getKitchenInfo} from '@/api/kitchen';
import {BusinessImage} from '@/components/BusinessImage';
import {Menu as MenuList, Path} from '@/routes';

import {KitchenChild} from './KitchChild';
import './index.less';

export const HealthyKitchen = () => {
  const navigate = useNavigate();
  const matchRoute = useMemo(() => {
    // 兼容 hash 模式和 history 模式的路由
    const path = location.hash || location.pathname;
    return findRouteWithPath(MenuList, path.replace('#', ''));
  }, [location.hash, location.pathname]);
  const selectedKeys = useMemo(() => {
    const key = matchRoute?.path ?? matchRoute?.key;
    const path2Code = {
      [Path.fatLossRecipe()]: 'RECIPE',
      [Path.lightCookingRecommendation()]: 'RECOMMEND',
    };

    return key ? [path2Code[key] ?? ''] : [];
  }, [matchRoute]);

  const {data: KitchenInfo} = useSWR(`[Kitchen]getKitchenInfo`, getKitchenInfo, NotRevalidateOption);
  const {data: kitchenList} = useSWR(`[Kitchen]getKitchenChildren`, getKitchenChildren, NotRevalidateOption);

  const handleTabChange = (key: string) => {
    const code2Path = {
      RECIPE: Path.fatLossRecipe(),
      RECOMMEND: Path.lightCookingRecommendation(),
    };
    const path = code2Path[key];
    navigate(path);
  };

  return (
    <Page className='healthy-kitchen'>
      <Carousel autoplay>
        <BusinessImage
          key={KitchenInfo?.attachment[0].id}
          src={KitchenInfo?.attachment[0].content}
          fileId={KitchenInfo?.attachment[0].id}
          alt={KitchenInfo?.attachment[0].fileName}
          width={'100%'}
          className={'max-h-472px h-472px w-100%'}
        />
      </Carousel>

      <div className='mx-190px mt-80px'>
        <Tabs
          centered
          activeKey={selectedKeys[0]}
          size='large'
          onChange={(key) => handleTabChange(key)}
          items={kitchenList?.map((kitchen) => ({
            label: <span className='text-[30px] font-bold'>{kitchen.title}</span>,
            key: kitchen.code,
            children: <KitchenChild code={kitchen.code}/>,
          }))}
        />
      </div>
    </Page>
  );
};
