import { Breadcrumb, Col, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IRoute } from '@web/components';
import logo from '@/assets/btLogo.svg';
import plLogo from '@/assets/plLogo.svg';
import Api from '@/constant/request';
import { Menu as MenuList } from '@/routes';
import { BusinessImage } from '../BusinessImage';
import './index.less';

const Footer = () => {
  const [concatList, setConcatList] = useState<any>([]);

  const location = useLocation();

  useEffect(() => {
    getlogo();
  }, []);

  const getlogo = async () => {
    const res = await Api.post('/api/info/list');
    setConcatList(res.data);
  };

  const findRoutePath = (menuList: IRoute[], path: string): IRoute[] => {
    for (let index = 0; index < menuList.length; index++) {
      const element = menuList[index];

      if (element.path === path) {
        return [element];
      }

      if (element.children) {
        const ret = findRoutePath(element.children, path);
        if (ret.length) {
          return [element, ...ret];
        }
      }
    }

    return [];
  };


  const breadcrumbItems = useMemo(() => {
    if (location.search.includes('recipe')) {
      return findRoutePath(MenuList, location.pathname);
    }
    return findRoutePath(MenuList, location.pathname + (location.search ?? ''));
  }, [location.pathname, location.search]);

  return (
    <div>
      <div className='margin-398 flex items-center pb-[15px] pt-[15px]'>
        <p className='text-[14px]'>当前位置：</p>
        <Breadcrumb separator='>'>
          {breadcrumbItems.map((item, index) => {
            return (
              <Breadcrumb.Item key={index} className='text-[14px]'>
                {item?.name}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </div>

      <footer className='flex flex-col border-none '>
        <div className='margin-398 flex justify-between'>
          <div className='flex-auto pt-[30px]'>
            <Row wrap={true}>
              {MenuList.map((item) =>
                Array.isArray(item.children) ? (
                  <Col span={4} className={'mt-[15px]'}>
                    <div className='footer-center' key={item.key}>
                      <span className='text-[18px] font-bold text-[#9C7651]'>{item.name}</span>
                      <div className='h-[2px] w-[50%] bg-[#9C7651] opacity-100' />
                      {item.children.map((child) => (
                        <div className='text-[14px] text-[#9C7651]' key={child.key}>
                          <Link to={child.path ?? '/'}>{child.name}</Link>
                        </div>
                      ))}
                    </div>
                  </Col>
                ) : null
              )}
            </Row>
          </div>

          <div className='w-500px flex flex-col items-end pt-[30px]'>
            <div className='logoQrcode'>
              <div>
                {' '}
                <img src={logo} alt='' className='btLogo' />
              </div>
              <p className='text-[14px] text-[#9C7651]'>电话：{concatList[2]?.value} </p>
              <p className='text-[14px] text-[#9C7651]'>邮箱：{concatList[3]?.value}</p>
              <div>
                {/* APP下载 */}
                <div>
                  {concatList[0]?.attachment?.map((item: any) => (
                    <div>
                      <BusinessImage className='h-100px w-100px' key={item.id} src={item.content} fileId={item.id} alt={item.fileName} />
                    </div>
                  ))}
                  <p className='text-[14px] text-[#9C7651]'>{concatList[0]?.name}</p>
                </div>
                {/* 微信公众号 */}
                <div>
                  {concatList[4]?.attachment?.map((item: any) => (
                    <div>
                      <BusinessImage className='h-100px w-100px' key={item.id} src={item.content} fileId={item.id} alt={item.fileName} />
                    </div>
                  ))}
                  <p className='text-[14px] text-[#9C7651]'>{concatList[4]?.name}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='mainhidder'></div>
        </div>
        <div>
          <div className='mt-[20px] border-t border-solid border-[#9C7651] opacity-30' />
          <Row gutter={[12, 12]} className='margin-398 py-[20px]'>
            <Col className='flex text-[14px] text-[#9C7651]' style={{ borderRight: '1px solid #9C7651' }} >
              <img src={plLogo} alt='' className={'w-4'} style={{}}/>
             <div style={{paddingTop:'1px'}}>京公网安备 11030102010484号</div> 
            </Col>

            <Col className='text-[14px] text-[#9C7651]' style={{ borderRight: '1px solid #9C7651' ,paddingTop:'1px'}} >京ICP备17042474号-1</Col>
            <Col className='text-[14px] text-[#9C7651]' style={{ borderRight: '1px solid #9C7651' ,paddingTop:'1px'}} >药品医疗器械网络信息服务备案（京）网药械信息备字（2024）第00192号</Col>
            <Col className='text-[14px] text-[#9C7651]' style={{ paddingTop:'1px'}} >第二类医疗器械经营备案凭证 京海药监械经营备20230131号</Col>
          </Row>
        </div>
          <div style={{marginLeft: '235px', marginBottom: '20px'}}>
            <Col className='flex text-[14px] text-[#9C7651]'>
              <img src={'https://img14.360buyimg.com/imagetools/jfs/t1/230576/36/23362/4543/668e7880F4af2e6e7/250de71289de111a.png'} alt='' width='40px' height='40px'/>
              <div style={{ width : '80px', marginLeft: '5px' ,paddingTop:'8px'}}><a className='text-[14px] text-[#9C7651]'  href="http://xxcx.yjj.beijing.gov.cn/eportal/ui?pageId=723923&id=9bae1e0ad7364460be9408a4a6049602" target="_blank" rel="noopener noreferrer" >北京市药品监督管理局</a></div>
            </Col>
            </div>
      </footer>
    </div>
  );
};

export default Footer;
