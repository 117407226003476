import QueueAnim from 'rc-queue-anim';
import {useEffect, useMemo, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import useSWR from 'swr';

import {NotRevalidateOption, usePagination} from '@web/common';
import {Page} from '@web/components';

import {getContent} from '@/api/about';
import {getBasicInfo, getChildInfo} from '@/api/home';
import banner1 from '@/assets/img/home/carousel1.png';
import left from '@/assets/left.svg';
import playImage from '@/assets/play.svg';
import right from '@/assets/right.svg';
import {BusinessImage} from '@/components/BusinessImage';
import {BusinessVideo} from '@/components/BusinessVideo';
import {CustomContainer} from '@/components/CustomContainer';
import {Business} from './Business';
import {Kitchen} from './Kitchen';
import './index.less';
import {SkeletonLoading} from "@/components/SkeletonLoading";

const oldSlideSetting = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: 'ease-in-out',
  draggable: false,
  arrows: false,
};

export const Home = () => {
  const [slideSetting, setSlideSetting] = useState({...oldSlideSetting});
  const {data: childinfo} = useSWR(`[Home]getChildInfo`, () => getChildInfo(), NotRevalidateOption);
  const {data: basicinfo} = useSWR(
    `[Home]getBasicInfo`,
    () => getBasicInfo(),
    NotRevalidateOption
  ); /* api/home/info中拿取banner图 */
  const {page, limit} = usePagination(1, -1);
  const [setting, setSetting] = useState({
    dots: false,
    infinite: false,
    slidesToShow: 4,
    centerPadding: '30px',
    autoplaySpeed: 2000,
  });

  const params = useMemo(() => {
    return {
      page: {
        pageNumber: page,
        pageSize: limit,
      },
      requestData: {
        code: childinfo?.[0]?.code ?? '',
      },
    };
  }, [page, limit, childinfo?.[0]]);

  const params1 = useMemo(() => {
    return {
      page: {
        pageNumber: page,
        pageSize: limit,
      },
      requestData: {
        code: childinfo?.[1]?.code ?? '',
      },
    };
  }, [page, limit, childinfo?.[1]]);

  const params2 = useMemo(() => {
    return {
      page: {
        pageNumber: page,
        pageSize: limit,
      },
      requestData: {
        code: childinfo?.[2]?.code ?? '',
      },
    };
  }, [page, limit, childinfo?.[2]]);

  const {data: content1, isValidating: loading1} = useSWR(
    childinfo?.[0]?.code ? `[Home]getContent${JSON.stringify(params)}` : null,
    () => getContent(params),
    NotRevalidateOption
  );

  const {data: content2} = useSWR(
    childinfo?.[1]?.code ? `[KICHEN]getContent${JSON.stringify(params1)}` : null,
    () => getContent(params1),
    NotRevalidateOption
  );

  const {data: content3, isValidating: videoLoading} = useSWR(
    childinfo?.[2]?.code ? `[KICHEN]getContent${JSON.stringify(params2)}` : null,
    () => getContent(params2),
    NotRevalidateOption
  );
  const BUSINESS = childinfo?.find((_: any) => _.code == 'BUSINESS');
  const KICHEN = childinfo?.find((_: any) => _.code == 'KICHEN');
  const HOMEVIDEO = childinfo?.find((_: any) => _.code == 'HOME_VIDEO');
  const BUSINESS1 = content1?.list[0];
  const BUSINESS2 = content1?.list[1];
  const BUSINESS3 = content1?.list[2];
  const KICHEN1 = content2?.list;
  const HOME_VIDEO = content3?.list;
  const [activeVideo, setActiveVideo] = useState<any>({});
  const [activeVideoPlay, setActiveVideoPlay] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [defauleItem, setDefauleItem] = useState(0);
  useEffect(() => {
    if (HOME_VIDEO && HOME_VIDEO?.length) {
      setActiveVideo(HOME_VIDEO[0]);
      setActiveVideoPlay(HOME_VIDEO[0].connectAttachment[0])
    }
  }, [HOME_VIDEO?.length]);

  useEffect(() => {
    if (activeVideo?.connectAttachment?.length < 4) {
      setSetting((pre) => {
        return {
          ...pre,
          slidesToShow: HOME_VIDEO[0].length,
        };
      });
    } else {
      setSetting((pre) => {
        return {
          ...pre,
          slidesToShow: 4,
        };
      });
    }
  }, [activeVideo]);

  const onMouseOver = () => {
    setSlideSetting({
      ...oldSlideSetting,
      arrows: true,
      nextArrow: <img src={right}/>,
      prevArrow: <img src={left}/>,
    });
  };
  const onMouseOut = () => {
    setSlideSetting({...oldSlideSetting});
  };

  return (
    <Page>
      <div className='homeTop'>
        <SkeletonLoading loading={!basicinfo?.attachment[0]}>
          <div onMouseMove={onMouseOver} onMouseLeave={onMouseOut}>
            <Slider {...slideSetting}>
              {basicinfo?.attachment.map((item: any) => (
                <BusinessImage
                  className='max-h-830px h-830px'
                  key={item.id}
                  src={item.content}
                  fileId={item.id}
                  alt={item.fileName}
                  width={'100%'}
                  showLogoSkeleton={true}
                  skeletonClass={'h-830px'}
                />
              ))}
            </Slider>
          </div>
        </SkeletonLoading>

        {/*七鲜各业态*/}
        <CustomContainer title={BUSINESS?.title} subTitle={BUSINESS?.enTitle}>
          <QueueAnim className='queue-simple mt-40px'>
            <Business BUSINESS1={BUSINESS1} BUSINESS2={BUSINESS2} BUSINESS3={BUSINESS3}/>
          </QueueAnim>
        </CustomContainer>

        {/* 健康厨房*/}
        <CustomContainer
          title={KICHEN?.title}
          subTitle={KICHEN?.enTitle}
          description={KICHEN?.copywriting}
          subClassName={'pb-0'}
        >
          <Kitchen KICHEN1={KICHEN1}/>
        </CustomContainer>
        {/*小七778*/}
        <CustomContainer title={HOMEVIDEO?.title} subTitle={HOMEVIDEO?.enTitle} subheading={HOMEVIDEO?.subheading}/>

        <div className='home_video mt-[30px] flex'>
          <SkeletonLoading loading={videoLoading} className={'margin-398 h-300px mb-20px'}>
            {HOME_VIDEO?.map((el: any) => (
              <div
                className='item'
                style={{
                  color: el.title == activeVideo.title ? '#D0A984' : '',
                  border: el.title == activeVideo.title ? '1px solid #D0A984' : '',
                  borderBottom: el.title == activeVideo.title ? '1px solid #ffffff' : '',
                }}
                onClick={() => {
                  setDefauleItem(0);
                  setActiveItem({});
                  setActiveVideo({
                    ...el,
                  });
                }}
              >
                {el?.title}
              </div>
            ))}
          </SkeletonLoading>
        </div>
      </div>
      <div className='sliderBot margin-398' style={{width: 'auto'}}>
        {
          <Slider {...setting} className={'h-174px'}>
            {activeVideo?.connectAttachment?.map((item: any, index: any) => (
              <div className='foodImg px-5'>
                <img className={'play-img'} src={playImage} alt=''/>
                <BusinessImage
                  key={item.attachment.id}
                  src={item.attachment.content}
                  fileId={item.attachment.id}
                  alt={item.attachment.fileName}
                  width={'100%'}
                  className={'max-h-160px h-160px w-100%'}
                  style={{
                    borderRadius: '10px',
                    boxShadow:
                      (activeVideo && item.attachment == activeItem.attachment) || index === defauleItem
                        ? '8px 2px 4px 1px rgba(0, 0, 0, 0.2)'
                        : '',
                  }}
                  onClick={() => {
                    const ele = activeVideo?.connectAttachment?.find(
                      (_: any) =>
                        _?.attachment.fileName.split('.')[0].trim() == item?.attachment.fileName.split('.')[0].trim()
                    );
                    setDefauleItem(index);
                    setActiveItem(ele);
                    setLoading(true);
                    setActiveVideoPlay(ele);
                    setLoading(false);
                  }}
                />
              </div>
            ))}
          </Slider>
        }

        {!!activeVideo?.connectAttachment?.length && (
          <div className='pb-20 pt-8'>
            {!loading &&
             <BusinessVideo
              poster={activeVideoPlay?.attachment?.content} 
              src={activeVideoPlay?.connectAttachment?.content}  
              fileId={activeVideoPlay?.connectAttachment?.id}
              />
            }
          </div>
        )}
      </div>
    </Page>
  );
};
