import {memo, useEffect, useState} from "react";
import { Image as ImageAntd, ImageProps} from 'antd';

interface ImgPreviewProps extends ImageProps {
  fileId?: string;
  className?: string;
  text?: string
}

export const ImgPreview = memo(function ({src, text = '查看配送范围', ...props}: ImgPreviewProps) {
  const [visible, setVisible] = useState(false);
  const [imgDownload, setImgDownload] = useState('');


  const getImageJs=()=>{
    const img = new Image();
    img.onload = function () {
      setImgDownload(src)
    }
    img.src = src;
  }

  useEffect(() => {
    setVisible(false)
    getImageJs();
  }, [src]);

  const handleImg = () => {
    if (imgDownload) {
      setVisible(true)
    }
  }


  return (
    <>
      <button
        className='peisong h-8 bg-[#D0A984] py-2 px-3.5 text-[14px] font-bold text-white'
        style={{flex: 'none'}}
        onClick={() => handleImg()}>{text}</button>

      <div style={{width: 0, display: 'none'}}>
        <ImageAntd
          className={props.className}
          placeholder={true}
          {...props}
          alt=''
          src={imgDownload}
          preview={{
            visible,
            scaleStep: 0.5,
            src: imgDownload,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        />
      </div>
    </>
  );
})





