import {Col, Row} from 'antd';
import useSWR from 'swr';
import {Page} from '@web/components';
import {NotRevalidateOption} from '@/../../common/src';
import {getChild, getJoinInfo} from '@/api/recruit';
import campusBgImg from '@/assets/img/recruit/campus.png';
import experiencedBgImg from '@/assets/img/recruit/experienced.png';
import {BusinessImage} from '@/components/BusinessImage';
import {CustomContainer} from '@/components/CustomContainer';
import RecruitCard from './RecruitCard';
import './index.less';
import {SkeletonLoading} from "@/components/SkeletonLoading";

export const RecruitHome = () => {
  const {data: joinInfo } = useSWR(`[Join]getJoinInfo`, () => getJoinInfo(), NotRevalidateOption);
  const {data: recruitList,isValidating: loading} = useSWR(`[About]getChild`, () => getChild(), NotRevalidateOption);

  const code2BgImg = {
    CAMPUS_RECRUITMENT: campusBgImg,
    SOCIAL_RECRUITMENT: experiencedBgImg,
  };

  return (
    <Page>
      <BusinessImage
        key={joinInfo?.attachment?.[0]?.id}
        src={joinInfo?.attachment?.[0]?.content}
        fileId={joinInfo?.attachment?.[0]?.id}
        alt={joinInfo?.attachment?.[0]?.fileName}
        width={'100%'}
        className={'max-h-472px h-472px w-100%'}
      />
        <div className='<md:py-56px margin-398'>
          <CustomContainer title={joinInfo?.title} subTitle={joinInfo?.enTitle}/>
          <div className='mt-40px flex justify-center'>
            <p
              style={{whiteSpace: 'pre'}}
              className='line-clamp-4 text-center text-[18px] font-normal leading-[32px] text-[#3d3d3d80]'
            >
              {joinInfo?.copywriting}
            </p>
          </div>
        </div>

        <Row className={'my-40px flex justify-center'} gutter={[12, 12]}>
          <SkeletonLoading loading={loading} className={'margin-398'}>
          {recruitList?.map((item: any, index: number) => (
            <Col>
              <RecruitCard key={index} {...item} bgImg={code2BgImg[item.code]}/>
            </Col>
          ))}
          </SkeletonLoading>
        </Row>

    </Page>
  );
};
