import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { IRoute, flatRouteTree } from '@web/components';

import { CarefullyChosen, HealthyKitchen, NotFound, RecipeDetail,PDF1,PDF2 } from '@/modules';
import { About, BrandUpdates, GoodProduct, Home, Life, PrivateBrand, Supermarket } from '@/modules';
import { RecruitDetail, RecruitHome } from '@/modules';

import { BasicLayout } from './app';

const urlWithQuery = (path: string, query?: URLSearchParams) => (query ? `${path}?${query.toString()}` : path);
const pathFactory = (path: string) => (query?: URLSearchParams) => urlWithQuery(path, query);

export const Path = {
  home: pathFactory('/'),
  AboutSevenDelicacies: pathFactory('/about-seven-fresh'),
  shop: pathFactory('/shop'),
  healthykitchen: pathFactory('/healthykitchen'),
  about: pathFactory('/about'),
  privateBrand: pathFactory('/private-brand'),
  brandUpdates: pathFactory('/brand-updates'),
  supermarket: pathFactory('/super-market'),
  life: pathFactory('/life'),
  goodProduct: pathFactory('/good-product'),
  bakery: pathFactory('/bakery'),
  food: pathFactory('/food'),
  wine: pathFactory('/wine'),
  aquaticProducts: pathFactory('/aquatic-products'),
  meat: pathFactory('/meat'),
  freezing: pathFactory('/freezing'),
  snacks: pathFactory('/snacks'),
  grain_oil_seasoning: pathFactory('/grain-oil-seasoning'),
  mother_baby: pathFactory('/mother-baby'),
  daily_necessities: pathFactory('/daily-necessities'),
  fatLossRecipe: pathFactory('/fat-loss-recipe'),
  lightCookingRecommendation: pathFactory('/light-cooking-recommendation'),
  join: pathFactory('/job'),
  jobList: pathFactory('/job/list'),
  choiceness: pathFactory('/choiceness'),
  recipeDetail: pathFactory('/recipe-detail'),
  pdf1: pathFactory('/pdf1'),
  pdf2: pathFactory('/pdf2'),
} as const;

export const Menu: IRoute[] = [
  {
    name: '首页',
    icon: null,
    index: true,
    key: 'home',
    path: Path.home(),
    component: <Home />,
  },
  {
    name: '关于七鲜',
    icon: null,
    key: 'about-seven-fresh',
    path: Path.AboutSevenDelicacies(),
    children: [
      {
        name: '七鲜是谁',
        icon: null,
        key: 'about',
        path: Path.about(),
        component: <About />,
      },
      {
        name: '自有品牌',
        icon: null,
        key: 'privateBrand',
        path: Path.privateBrand(),
        component: <PrivateBrand />,
      },
      {
        name: '品牌动态',
        icon: null,
        key: 'brandUpdates',
        path: Path.brandUpdates(),
        component: <BrandUpdates />,
      },
    ],
  },
  {
    name: '七鲜门店',
    icon: null,
    key: 'shop',
    path: Path.shop(),
    children: [
      {
        name: '七鲜超市',
        icon: null,
        key: 'supermarket',
        path: Path.supermarket(),
        component: <Supermarket />,
      },
      {
        name: '七鲜生活',
        icon: null,
        key: 'life',
        path: Path.life(),
        component: <Life />,
      },
      {
        name: '七鲜良品',
        icon: null,
        key: 'goodProduct',
        path: Path.goodProduct(),
        component: <GoodProduct />,
      },
    ],
  },
  {
    name: '七鲜精选',
    icon: null,
    key: 'choiceness',
    path: Path.choiceness(),
    component: <CarefullyChosen />,
  },
  {
    name: '七鲜精选',
    icon: null,
    key: 'choiceness',
    path: Path.choiceness(),
    component: <CarefullyChosen />,
    hidden: true,
    children: [
      {
        name: '烘焙',
        icon: null,
        key: 'bakery',
        path: Path.choiceness() + '?key=BAKE',
        component: <CarefullyChosen />,
        hidden: true,
      },
      {
        name: '餐饮',
        icon: null,
        key: 'food',
        path: Path.choiceness() + '?key=CATERING',
        component: <CarefullyChosen />,
        hidden: true,
      },
      {
        name: '酒水',
        icon: null,
        key: 'wine',
        path: Path.choiceness() + '?key=WINE',
        component: <CarefullyChosen />,
        hidden: true,
      },
      {
        name: '水产',
        icon: null,
        key: 'aquaticProducts',
        path: Path.choiceness() + '?key=AQUATIC',
        component: <CarefullyChosen />,
        hidden: true,
      },
      {
        name: '肉禽蛋',
        icon: null,
        key: 'meat',
        path: Path.choiceness() + '?key=MEAT',
        component: <CarefullyChosen />,
        hidden: true,
      },
      {
        name: '冷冻',
        icon: null,
        key: 'freezing',
        path: Path.choiceness() + '?key=FREEZING',
        component: <CarefullyChosen />,
        hidden: true,
      },
      {
        name: '休闲食品',
        icon: null,
        key: 'snacks',
        path: Path.choiceness() + '?key=SNACKS',
        component: <CarefullyChosen />,
        hidden: true,
      },
      {
        name: '粮油调味',
        icon: null,
        key: 'grain_oil_seasoning',
        path: Path.choiceness() + '?key=GRAIN_AND_OIL_SEASONING',
        component: <CarefullyChosen />,
        hidden: true,
      },
      {
        name: '母婴',
        icon: null,
        key: 'mother_baby',
        path: Path.choiceness() + '?key=MOTHER_AND_BABY',
        component: <CarefullyChosen />,
        hidden: true,
      },
      {
        name: '日用品',
        icon: null,
        key: 'daily_necessities',
        path: Path.choiceness() + '?key=DAILY_NECESSITIES',
        component: <CarefullyChosen />,
        hidden: true,
      },
    ],
  },
  {
    name: '健康厨房',
    icon: null,
    key: 'healthykitchen',
    path: Path.healthykitchen(),
    children: [
      {
        name: '减脂期食谱',
        icon: null,
        key: 'fatLossRecipe',
        path: Path.fatLossRecipe(),
        component: <HealthyKitchen />,
      },
      {
        name: '轻烹饪食谱',
        icon: null,
        key: 'lightCookingRecommendation',
        path: Path.lightCookingRecommendation(),
        component: <HealthyKitchen />,
      },
    ],
  },
  {
    name: '健康厨房详情',
    icon: null,
    hidden: true,
    key: 'recipeDetail',
    path: Path.recipeDetail(),
    component: <RecipeDetail />,
  },
  {
    name: '加入七鲜',
    icon: null,
    key: 'job',
    path: Path.join(),
    component: <RecruitHome />,
  },
  {
    name: '加入七鲜',
    icon: null,
    key: 'job',
    hidden: true,
    path: Path.join(),
    component: <RecruitHome />,
    children: [
      {
        name: '校园招聘',
        icon: null,
        hidden: true,
        key: 'campusJob',
        path: Path.jobList() + '?code=CAMPUS_RECRUITMENT',
        component: <RecruitDetail />,
      },
      {
        name: '社会招聘',
        icon: null,
        hidden: true,
        key: 'socialJob',
        path: Path.jobList() + '?code=SOCIAL_RECRUITMENT',
        component: <RecruitDetail />,
      },
    ],
  },
  {
    name: '招聘详情',
    icon: null,
    key: 'jobList',
    path: Path.jobList(),
    component: <RecruitDetail />,
    hidden: true,
  },
  {
    name: '',
    icon: null,
    key: 'job',
    hidden: true,
    path: Path.join(),
    // component: <RecruitHome />,
    children: [
      {
        name: '',
        icon: null,
        key: 'pdf1',
        path: Path.pdf1(),
        component: <PDF1 />,
      },
      {
        name: '',
        icon: null,
        key: 'pdf2',
        path: Path.pdf2(),
        component: <PDF2 />,
      },
    ],
  },
  {
    name: '招聘详情',
    icon: null,
    key: 'jobList',
    path: Path.jobList(),
    component: <RecruitDetail />,
    hidden: true,
  },
];

export const RouterComponent = () => {
  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route element={<BasicLayout />}>
          {flatRouteTree(Menu)
            .filter((r) => r.path && r.component)
            .map((route) => <Route key={route.path} path={route.path} element={route.component}></Route>)
            .concat([
              <Route key='redirect' path='/' element={<Navigate to={Path.home()} replace />} />,
              <Route key='404' path='*' element={<NotFound />} />,
            ])}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
