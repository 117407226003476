import {Link, NavLink, useNavigate} from 'react-router-dom';

import { Attachment } from '@/interface/universe';
import { Path } from '@/routes';

import './RecruitCard.less';

interface Props {
  title: string;
  enTitle: string;
  code: string;
  bgImg: string;
  attachment: Attachment[];
}

export default function RecruitCard(props: Props) {
  const navigate = useNavigate();

  return (
    <section className='recruit-card' onClick={()=> {
      console.log(111,Path.jobList())
      navigate({
        pathname: Path.jobList(),
        search: `code=${props.code}`,
      });

    }}>
      <img className='recruit-card-bg' alt={props.title} src={props.bgImg} />

      <h3>
        <div className='recruit-card-title'>{props.title}</div>
        <div className='recruit-card-en'>{props.enTitle}</div>
      </h3>

      <div className='recruit-card-enter <md:w-100% '>
        <NavLink to={Path.jobList() + '?code=' + props.code}>立即加入</NavLink>
      </div>
    </section>
  );
}
