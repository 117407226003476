
export const PDF2 = () => {

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src="https://img14.360buyimg.com/imagetools/jfs/t1/235449/8/22275/1606803/668e76c3F1972e433/bf9b964b200b7c78.png"
        alt=""
        style={{ width: 'auto', height: '800px', marginTop:'50px' }}
      />
    </div>
  );
};
