import { Popover } from 'antd';
import { useEffect, useState } from 'react';

import message from '@/assets/message.svg';
import phone from '@/assets/phone.svg';
import reTop from '@/assets/reTop.svg';
import Api from '@/constant/request';

import { BusinessImage } from '../BusinessImage';
import './index.less';

const Float = () => {
  const [visibleBackTopBtn, setVisibleBackTopBtn] = useState(false);
  const handleScroll = () => {
    const h5Scroll = {
      scrollTop: 0,
    };
    if (document?.documentElement && document?.documentElement?.scrollTop) {
      h5Scroll.scrollTop = document.documentElement.scrollTop;
    } else if (document?.body) {
      h5Scroll.scrollTop = document.getElementById('site-layout-container1')?.scrollTop ?? 0;
    }
    if (h5Scroll.scrollTop > 300) {
      setVisibleBackTopBtn(true);
    } else {
      setVisibleBackTopBtn(false);
    }
  };
  // 点击按钮事件处理函数
  const backToTopHandle = () => {
    document.getElementById('site-layout-container1')?.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [concatList, setConcatList] = useState<any>([]);

  useEffect(() => {
    getlogo();
  }, []);

  const getlogo = async () => {
    const res = await Api.post('/api/info/list');
    setConcatList(res.data);
  };

  /* APP下载 */
  const content1 = (
    <div className='flex w-28 flex-col items-center  justify-center'>
      {concatList[0]?.attachment?.map((item: any) => (
        <div>
          <BusinessImage key={item.id} src={item.content} fileId={item.id} alt={item.fileName}  className={'w-25 h-25'} />
        </div>
      ))}
      <p className='text-[12px] text-[#3D3D3D]'>手机下单3公里内</p>
      <p className='text-[12px] text-[#3D3D3D]'>最快30分钟到达</p>
    </div>
  );

  /* 加入社群 */
  const content2 = (
    <div className='flex w-28 flex-col items-center justify-center'>
      {concatList[1]?.attachment?.map((item: any) => (
        <div>
          <BusinessImage key={item.id} src={item.content} fileId={item.id} alt={item.fileName} className={'w-20 h-20'}/>
        </div>
      ))}
      <p className='text-[12px] text-[#3D3D3D]'>微信扫一扫</p>
      <p className='text-[12px] text-[#3D3D3D]'>加入社群&nbsp;专属等你享</p>
    </div>
  );

  return (
    <>
      {visibleBackTopBtn && (
        <div className='float'>
          <div className='big-border <md:w-12 <md:h-36 >lg:w-100px'>
            <Popover content={content1} placement='left'>
              <div
                className='h-100px w-100px <md:w-12
               <md:h-12 flex
              flex-col items-center justify-center bg-[#FFF1DA]
              text-center hover:bg-white hover:opacity-100'
              >
                <img src={phone} />
                <p className='text-[14px] text-[#9C7651]'>{concatList[0]?.name}</p>
              </div>
            </Popover>
            <Popover content={content2} placement='left'>
              <div
                className='h-100px w-100px borderColor <md:w-12 <md:h-12
              flex flex-col
              items-center justify-center bg-[#FFF1DA] text-center hover:bg-white hover:opacity-100'
              >
                <img src={message} />
                <p className='text-[14px] text-[#9C7651]'>{concatList[1]?.name}</p>
              </div>
            </Popover>
            <div
              className='h-100px w-100px <md:w-12 <md:h-12 flex
               flex-col items-center
              justify-center bg-[#FFF1DA] text-center hover:bg-white hover:opacity-100'
              onClick={backToTopHandle}
            >
              <img src={reTop} />
              <div className='text-[14px] text-[#9C7651]'>返回顶部</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Float;
