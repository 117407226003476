import { Draft } from 'immer';
import { DraftFunction } from 'use-immer';

/**
 * to return Promise<[T, Error]>
 * @param {Promise<T>} promise
 */
export async function to<T, E = Error>(promise: Promise<T>): Promise<[T, E]> {
  try {
    const ret = await promise;
    return [ret, null as unknown as E];
  } catch (e: any) {
    return [null as unknown as T, e];
  }
}

/**
 * toSync return [T, Error]
 * @param {() => T} fn
 */
export function toSync<T, E = Error>(fn: () => T): [T, E] {
  try {
    return [fn(), null as unknown as E];
  } catch (e: any) {
    return [null as unknown as T, e];
  }
}

export function warpImmerSetter<T extends object>(rawSet: (arg: DraftFunction<T>) => void) {
  function set(data: Partial<T>): void;
  function set(f: (draft: T | Draft<T>) => void): void;
  function set<K extends keyof T>(key: K, value: T[K]): void;
  function set<K extends keyof T>(data: any, value?: T[K]): void {
    if (typeof data === 'string') {
      rawSet((draft: Draft<T>) => {
        const key = data as K;
        const v = value as T[K];
        const d = draft as T;
        d[key] = v;
      });
    } else if (typeof data === 'function') {
      rawSet(data);
    } else if (typeof data === 'object') {
      rawSet((draft: Draft<T>) => {
        const obj = data as T;
        for (const key of Object.keys(obj)) {
          const k = key as keyof T;
          const d = draft as T;
          d[k] = obj[k];
        }
      });
    }
  }

  return set;
}
