import classnames from 'classnames';
import {useEffect, useState} from 'react';
import {ReactNode} from 'react';
import bgLoading from "@/assets/bgLoading.png";

import './index.less';


export interface BusinessCardProps {
  bgUrl: string;
  logo: string;
  position?: 'left' | 'right';
  description: ReactNode;
  isChangeSize?: boolean;
  src?: string;
}

export const BusinessCard = ({ bgUrl, ...props }: BusinessCardProps) => {

  const [imgLoadEnd, setImgLoadEnd] = useState(false);

  const getImageJs=(imgSrc:string)=>{
    const img = new Image();
    img.onload = function () {
      setImgLoadEnd(true);
    }
    img.src = imgSrc;
  }

  useEffect(() => {
      getImageJs(bgUrl)
  }, [bgUrl]);

  return (
    <div
      className={classnames(
        'h-520px mgFlexs mb-5 flex w-full items-center',
        props.position === 'right' && 'flex-row-reverse'
      )}
      style={{
        background: `url(${imgLoadEnd?bgUrl:bgLoading}) center center /cover`,
      }}
    >
      <div
        className={classnames(
          'text-card imgFlexsg backdrop-blur-490px hover: h-[280px] w-[476px] items-center bg-[#311E0B] bg-opacity-60 py-[30px] px-[20px] hover:ml-[0px] hover:flex hover:h-full hover:w-full hover:flex-col hover:justify-center hover:lg:px-[20px] ',
          !props.position || props.position === 'left' ? 'ml-18 hover:ml-0' : '<sm:mr-[10px] mr-18 hover:mr-0'
        )}
      >  
        <img src={props.logo} className={'h-76px'}/>

        <div className='my-[20px] h-[2px] w-[56px] bg-white'/>
        <div className={'full-box'}>
          <span
            className='>lg:line-clamp-4 leading-30px
          <md:line-clamp-2 <md:leading-50px
          line-clamp-4 text-[18px]
          font-normal text-white
          text-justify'
          >
            {props.description}
          </span>
        </div>
      </div>
    </div>
  );
};
