import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { useState,useEffect } from 'react';
import 'virtual:windi.css';
import 'video-react/dist/video-react.css';

import '@/assets/global.less';
import '@/assets/navbar.less';
import Float from '@/components/Float';
import Footer from '@/components/Footer';
import Header from '@/components/Header.tsx';

import './antd.less';
import './style.less';

const { Content } = Layout;

export const BasicLayout = () => {
  
  const [pdf, setPdf] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    if(params.has('pdf')){
      setPdf(true)
    }
  }, []);

  return (
    <Layout>
      <Layout className='site-layout'>
        {!pdf  && (
          <Header />
        )}
        <Content className='site-layout-container' style={{ backgroundColor: 'white' }} id='site-layout-container1'>
          <Outlet />
          {!pdf  && (
          <Footer />
        )}
        </Content>
      </Layout>
      {!pdf  && (
          <Float />
        )}
    </Layout>
  );
};
