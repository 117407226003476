import Api from '@/constant/request';

export async function getFeaturedChildren() {
  const resp = await Api.post('/api/featured/child');
  return resp.data;
}

export async function getFeatureList() {
  const resp = await Api.post('/api/content/list');
  return resp.data;
}
