import Api from '@/constant/request';

export async function getBasicInfo() {
  const resp = await Api.post('/api/home/info');
  return resp.data;
}

export async function getChildInfo() {
  const resp = await Api.post('/api/home/child');
  return resp.data;
}