import {Col, Pagination, Row, Spin} from 'antd';
import {useEffect, useMemo, useState} from 'react';
import useSWR from 'swr';

import {NotRevalidateOption, usePagination} from '@web/common';

import {getContent} from '@/api/about';
import {UniverseData} from '@/interface/universe';

import {FoodCard} from './FoodCard';
import './KitchChild.less';
import {SkeletonLoading} from "@/components/SkeletonLoading";

interface Props {
  code: string;
}

export const KitchenChild = (props: Props) => {
  const [showList, setShowList] = useState<any[]>([]);
  const [totalList, setTotalList] = useState<any>([]);

  const {page, limit, goto} = usePagination(1, 9);

  const params = useMemo(
    () => ({
      page: {pageNumber: 1, pageSize: 999},
      requestData: {code: props.code},
    }),
    [props.code]
  );

  const {data, isValidating: loading} = useSWR(
    `[Recipe]getContent/${JSON.stringify(params)}`,
    () => getContent(params),
    NotRevalidateOption
  );

  const loadData = (totalList: any[]) => {
    const endIdx = page * limit;
    const startIdx = endIdx - limit;
    const list = totalList.slice(startIdx, endIdx);
    setShowList(list);
  };

  const initData = () => {
    if (data) {
      const list = data.list.filter((item: any) => item.title !== '轮播图');
      loadData(list);
      setTotalList(list);
    }
  };

  useEffect(() => {
    loadData(totalList);
  }, [page]);
  useEffect(initData, [data]);
  useEffect(initData, []);

  return (
    <SkeletonLoading loading={loading}>
      <div className='py-17 lg:px-30 flex flex-col items-center sm:px-4 md:px-6 lg:px-8'>
        <Row className={'w-1130px'} gutter={[12, 12]}>
          {showList.map((item: UniverseData) => (
            <Col xs={24} md={12} lg={8} xl={8} key={item.id}>
              <FoodCard title={item.title} src={item.attachment?.[0].content} foodId={item.id} bgUrl={item.attachment?.[0].id} rate={item.star}/>
            </Col>
          ))}
        </Row>
        <div className='mt-17 flex justify-center'>
          <Pagination current={page} total={totalList.length} pageSize={limit} onChange={goto}/>
        </div>
      </div>
    </SkeletonLoading>
  );
};
