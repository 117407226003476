import { Col, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { NotRevalidateOption, usePagination } from '@web/common';
import { Page } from '@web/components';

import { getCityList, getShopInfoById, getShopList, getSupermarket } from '@/api/shop';
import map2 from '@/assets/img/about/map2.png';
import { BusinessImage } from '@/components/BusinessImage';
import { CustomContainer } from '@/components/CustomContainer';

import { ExampleMap } from '../map';
import './index.less';
import { ImgPreview } from "@/components/ImgPreview";
import { useNavigate } from 'react-router-dom';

export const Supermarket = () => {
  const { page, limit } = usePagination(1, -1);

  const [markerInfo, setMarkerInfo] = useState({
    type: '',
    city: '',
    name: '',
    attachment: {} as any,
  });

  const params = useMemo(
    () => ({
      page: {
        pageNumber: page,
        pageSize: limit,
      },
      requestData: {
        cityId: markerInfo.city,
        typeId: markerInfo.type,
      },
    }),
    [page, limit, markerInfo.city, markerInfo.type]
  );

  const { data: living } = useSWR(`[Shop]getSupermarket`, () => getSupermarket());

  const cityParams = {
    page: {
      pageNumber: 1,
      pageSize: -1,
    },
    requestData: {
      typeId: '13',
      isRecruit: false,
    },
  };

  const { data } = useSWR(
    `[Shop]getShopList/${JSON.stringify(params)}`,
    () => getShopList(params),
    NotRevalidateOption
  );

  const { data: cityList } = useSWR(
    `[City]getCityList/${JSON.stringify(cityParams)}`,
    () => getCityList(cityParams),
    NotRevalidateOption
  );

  useEffect(() => {
    if (cityList?.list && cityList?.list?.length > 0) {
      setMarkerInfo({
        type: '13',
        city: cityList?.list[0].id,
        name: cityList?.list[0].name,
        attachment: cityList?.list[0].cityType[0].attachment[0],
      });
    }
  }, [cityList?.list]);

  useEffect(() => {
    if (data?.list && data?.list?.length > 0) {
      setActiveAddress({
        ...data?.list[0],
      });
    }
  }, [data?.list]);

  const [activeAddress, setActiveAddress] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState<any>({});

  const getDefaultData = async () => {
    setLoading(true);
    const detail = await getAboutDetail();
    setDetail(detail);
    setLoading(false);
  };

  const getAboutDetail = () => {
    return getShopInfoById({ requestData: { id: activeAddress.id } });
  };

  useEffect(() => {
    if (activeAddress?.id) {
      getDefaultData();
    }
  }, [activeAddress.id]);
  const navigate = useNavigate();
  /* 七鲜超市 */
  const gotoSupermarket = () => {
    navigate('/super-market', { replace: true });
  };
  /* 七鲜生活 */
  const gotoLife = () => {
    navigate('/life', { replace: true });
  };
  /* 七鲜良品 */
  const gotoGoodProduct = () => {
    navigate('/good-product', { replace: true });
  };
  return (

    <Page>
      <BusinessImage
        key={living?.attachment[0]?.id}
        src={living?.attachment[0]?.content}
        fileId={living?.attachment[0]?.id}
        alt={living?.attachment[0]?.fileName}
        width={'100%'}
        className={'max-h-472px h-472px w-100%'}
      />

      <div className='<md:py-56px py-50px margin-398'>
        {/* <CustomContainer title={living?.title} subTitle={living?.enTitle} /> */}
        {/* 标题 */}
        <div className='titles flex justify-around items-center mt-[80px] pr-[200px] pl-[200px] border-b border-solid border-[#e5e7eb]'>

          <div className='text-[35px] font-bold pb-[20px] text-[#D0A984] border-b-2 border-solid border-[#D0A984] cursor-pointer' onClick={gotoSupermarket}>七鲜超市</div>
          <div className='text-[30px] font-bold mb-[20px] text-[#3d3d3d80] cursor-pointer' onClick={gotoLife}>七鲜生活</div>
          <div className=' text-[30px] font-bold mb-[20px] text-[#3d3d3d80] cursor-pointer' onClick={gotoGoodProduct}>七鲜良品</div>
        </div>
        <div className='pt-80px map-img flex items-center justify-center'>
          <BusinessImage
            key={markerInfo?.attachment?.id}
            src={markerInfo?.attachment?.content}
            fileId={markerInfo?.attachment?.id}
            alt={markerInfo?.attachment?.fileName}
            showLogoSkeleton={true}
            className={'h-696px'}
          />
        </div>
        <div
          className='border-b-1 tabCity flex justify-center border-solid border-[#D8D8D8] pt-20'
          style={{ overflow: 'hidden' }}
        >
          {(cityList?.list || [])
            .filter((_) => _.storeNumber)
            .map(
              (item, index) =>
                +item.storeNumber > 0 && (
                  <div
                    key={index}
                    onClick={() => {
                      setMarkerInfo({
                        type: '13',
                        city: item.id,
                        name: item.name,
                        attachment: item.cityType[0].attachment[0],
                      });
                    }}
                    className='tabCityItem flex cursor-pointer items-center pb-4 text-[18px] font-normal text-[#3d3d3d80]'
                  >
                    <div>
                      <span
                        className='border-solid pb-[12px] text-[18px] font-normal text-[#3d3d3d80] '
                        style={{
                          color: markerInfo.city == item.id ? '#3D3D3D' : '#3d3d3d80',
                          borderBottomWidth: markerInfo.city == item.id ? '3px' : '0px',
                          borderBottomColor: markerInfo.city == item.id ? '#3D3D3D' : '',
                        }}
                      >
                        {item.name}
                      </span>
                    </div>
                    <span
                      className='text-[14px] font-normal text-[#3d3d3d80]'
                      style={{ color: markerInfo.city == item.id ? '#3D3D3D' : '#3d3d3d80' }}
                    >
                      ({item.storeNumber})
                    </span>
                    {index < (cityList?.list || []).length - 1 && <span className='mx-7'>|</span>}
                  </div>
                )
            )}
        </div>

        <Row gutter={[8, 16]} className='<md:py-7 <md:h-240 h-120 border-1 mt-10 flex border-solid border-[#D8D8D8]'>
          <Col xs={24} sm={24} md={9} lg={9} xl={9} className='<md:h-120 colStyle flex h-[100%] flex-col p-0'>
            <div
              className='p-5.5 border-b-1 flex items-end border-solid border-[#D8D8D8] text-[24px] font-normal text-[#3D3D3D]'>
              {markerInfo.name}
              <span className='text-[18px] font-normal text-[#3D3D3D]'>（{data?.list.length}）</span>
            </div>
            <div
              className='overflowStyle flex-grow overflow-scroll text-[18px] font-normal text-[#3D3D3D] '
              style={{ overflowX: 'hidden' }}
            >
              {data?.list.map((_, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setActiveAddress({
                      ..._,
                    });
                  }}
                  className='flex cursor-pointer items-center py-4 px-11 opacity-100'
                  style={{ background: activeAddress.name == _.name ? '#FFF1DA' : '' }}
                >
                  {activeAddress.name == _.name && <img src={map2} className='mr-2 h-3.5 w-3.5' />}
                  {_.name}
                </div>
              ))}
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={15}
            lg={15}
            xl={15}
            className='border-l-1 <md:h-120 flex flex-col border-solid border-[#D8D8D8] p-0'
          >
            <div className='flex-grow'>
              <ExampleMap activeAddress={activeAddress} />
            </div>
            <div className='pei flex justify-between p-5'>
              <div>
                <div
                  className='w-46 border-b-1 border-solid border-[#D8D8D8] pb-2 text-[18px] font-bold text-[#3D3D3D]'>
                  {activeAddress.name}
                </div>
                <div className='pt-4 text-[14px] font-normal text-[#3D3D3D]'>
                  营业时间：{activeAddress.startTimeOffline}~{activeAddress.endTimeOffline}（线下）{' '}
                  {activeAddress.startTimeOnline}~{activeAddress.endTimeOnline}（线上）
                </div>
                <div className='pt-4 text-[14px] font-normal text-[#3D3D3D]'>{activeAddress.address}</div>
              </div>
              <ImgPreview src={detail?.attachment?.[0]?.content} />
            </div>
          </Col>
        </Row>
      </div>
    </Page>

  );
};
