import { Image as ImageAntd, ImageProps, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { downloadImage } from '@/api/file';

import './index.less';
import { SkeletonLoading } from "@/components/SkeletonLoading";

interface BusinessImageProps extends ImageProps {
  fileId?: string;
  className?: string;
  showSkeleton?: boolean;
  showLogoSkeleton?: boolean;
  skeletonClass?: string;
  src?: string | undefined;
}

export const BusinessImage = ({ fileId, showSkeleton = true, showLogoSkeleton = false, src, ...props }: BusinessImageProps) => {
  const { preview = false } = props;
  const [imgBase64, setImgBase64] = useState(src ? src : '');
  const [imgLoadEnd, setImgLoadEnd] = useState(false);

  const getImageBase64 = async () => {
    const base64 = await downloadImage(fileId);
    if(base64){
      setImgBase64(base64);
      setImgLoadEnd(true);
    }
  };

  const getImageJs=(imgSrc:string)=>{
    const img = new Image();
    img.onload = function () {
      setImgLoadEnd(true);
    }
    img.src = imgSrc;
  }

  useEffect(() => {
    if(src){
      getImageJs(src)
    }else{
      getImageBase64();
    }

  }, [fileId]);

  const handleSkeleton = () => {
    if (showSkeleton) {
      return showLogoSkeleton
        ? <SkeletonLoading loading={!imgLoadEnd} className={props.skeletonClass} />
        : <Skeleton.Image active />
    }
    return ''
  }

  return (
      <div className={'flex'} style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        {!imgLoadEnd ? handleSkeleton()
          : (
            <ImageAntd className={props.className} placeholder={true} preview={preview} {...props} src={src||imgBase64} alt='' />
          )}
      </div>
  );
};
