import { useForceUpdate } from '@mantine/hooks';
import { useDeepCompareEffect, usePrevious, useSyncedRef, useToggle } from '@react-hookz/web/esm';
import type { TablePaginationConfig } from 'antd';
import produce, { type Draft } from 'immer';
import { clamp, cloneDeep, isBoolean, isEmpty, isNumber } from 'lodash';
import moment from 'moment';
import { RefCallback, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { to, warpImmerSetter } from '../utils';

export function useObject<T extends Record<string, any>>(initialValue: T) {
  const [copy, rawSet] = useImmer(initialValue);
  const set = useMemo(() => warpImmerSetter(rawSet), [rawSet]);

  return [copy, set] as [T, any];
}

export function useManualQuery<T, Args extends any[]>(fn: (...args: Args) => Promise<T>) {
  const [loading, toggleLoading] = useToggle(false);
  const refFn = useRef(fn);
  useEffect(() => {
    refFn.current = fn;
  });

  const mutate = useCallback(
    async (...args: Args) => {
      toggleLoading(true);
      const [ret, err] = await to(refFn.current(...args));
      toggleLoading(false);
      if (err) {
        throw err;
      }
      return ret;
    },
    [toggleLoading]
  );
  return { mutate, loading };
}

export function usePagination(propsPage = 1, propsLimit = 20) {
  const prePropsPage = usePrevious(propsPage);
  const prePropsLimit = usePrevious(propsLimit);

  const page = useRef(propsPage);
  const limit = useRef(propsLimit);

  if (prePropsPage !== propsPage) {
    page.current = propsPage;
  }

  if (prePropsLimit !== propsLimit) {
    limit.current = propsLimit;
  }

  // 为了能让 antdPagination 能触发重新渲染而做的 workaround
  const forceUpdate = useForceUpdate();

  const offset = (page.current - 1) * limit.current;
  const next = useCallback(() => {
    page.current += 1;
    forceUpdate();
  }, [forceUpdate]);
  const prev = useCallback(() => {
    page.current = clamp(page.current - 1, 0, Number.MAX_SAFE_INTEGER);
    forceUpdate();
  }, [forceUpdate]);
  const goto = useCallback(
    (p: number) => {
      page.current = p;
      forceUpdate();
    },
    [forceUpdate]
  );
  const reset = useCallback(() => {
    page.current = 1;
    forceUpdate();
  }, [forceUpdate]);
  const setLimit = useCallback(
    (l: number) => {
      limit.current = l;
      forceUpdate();
    },
    [forceUpdate]
  );

  const antdPagination: TablePaginationConfig = {
    pageSize: limit.current,
    current: page.current,
    onChange: (page, limit) => {
      goto(page);
      setLimit(limit);
    },
    showSizeChanger: true,
  };

  return {
    page: page.current,
    limit: limit.current,
    setLimit,
    offset,
    next,
    prev,
    goto,
    reset,
    antdPagination,
  };
}

export function useJsonQuery<T extends Record<string, any>>(
  parser: (params: URLSearchParams) => T,
  serializer: (data: T) => URLSearchParams,
  initial: T
) {
  const parserRef = useSyncedRef(parser);
  const serializerRef = useSyncedRef(serializer);
  const initialRef = useSyncedRef(initial);

  const localtion = useLocation();
  const locationRef = useSyncedRef(localtion);
  const navigate = useNavigate();
  const [copy, setCopy] = useObject(initial);
  const parsedParams = useMemo(() => {
    const query = new URLSearchParams(localtion.search);
    return parserRef.current(query);
  }, [localtion.search, parserRef]);

  const targetValue = useMemo(() => ({ ...initialRef.current, ...parsedParams }), [initialRef, parsedParams]);

  useDeepCompareEffect(() => setCopy(targetValue), [targetValue, setCopy]);

  const setValue = useCallback(
    (fn: (draft: Draft<T>) => void, option?: NavigateOptions) => {
      const obj = { ...parsedParams, ...produce(targetValue, fn) };
      const newSearch = `?${serializerRef.current(obj).toString()}`;

      const isFirstInitialChange =
        locationRef.current.search === '' && newSearch === `?${serializerRef.current(initialRef.current).toString()}`;

      if (newSearch !== locationRef.current.search) {
        setCopy(obj);
        navigate(
          { hash: locationRef.current.hash, search: newSearch },
          { ...option, replace: option?.replace ?? isFirstInitialChange }
        );
      }
    },
    [parsedParams, targetValue, serializerRef, locationRef, initialRef, setCopy, navigate]
  );
  const syncValue = useCallback(() => setValue(() => copy), [copy, setValue]);

  return {
    copy,
    setCopy,
    value: targetValue,
    setValue,
    syncValue,
  };
}

// filter false | '' | undefined | 0 | []
export function isZeroValue(value: unknown): boolean {
  return isEmpty(value) && !((isNumber(value) && value !== 0) || (isBoolean(value) && value));
}

type simpleValue = string | number | string[] | boolean | undefined;

export function useSimpleJsonQuery<T extends Record<string, simpleValue>>(initial: T) {
  const [innerInitial, setInitial] = useState(initial);
  useDeepCompareEffect(() => setInitial(initial), [initial]);

  const simpleParser = useCallback(
    (params: URLSearchParams): T => {
      const keys = Object.keys(innerInitial);
      const result: Record<string, simpleValue> = {};
      for (const key of keys) {
        const originalValue = innerInitial[key];
        if (params.has(key)) {
          if (Array.isArray(originalValue)) {
            result[key] = params.get(key)!.split(',');
          } else if (isNumber(originalValue)) {
            result[key] = parseInt(params.get(key)!, 10);
          } else if (isBoolean(originalValue)) {
            result[key] = params.get(key) === 'true';
          } else {
            result[key] = params.get(key)!;
          }
        }
      }
      return result as T;
    },
    [innerInitial]
  );

  const simpleSerializer = useCallback((data: T): URLSearchParams => {
    const res = cloneDeep(data);
    for (const i in res) {
      if (isZeroValue(res[i])) {
        delete res[i];
      }
    }
    const search = new URLSearchParams(res as Record<string, string>);
    search.sort();
    return search;
  }, []);

  return useJsonQuery(simpleParser, simpleSerializer, initial);
}

function typeGuard(value: number | string, newValue: any) {
  let ret = newValue;
  if (typeof value === 'number') {
    const tryNumber = parseFloat(newValue.toString());
    ret = tryNumber || 0;
  }

  return ret;
}

export function useValueBinding<T extends Record<string, any>>(value: T, setter: any) {
  return useCallback(
    (key: keyof T) => {
      return {
        value: value[key],
        onChange: (param: any) => {
          if (param?.target?.value !== undefined) {
            setter(key as string, typeGuard(value[key], param?.target?.value));
            return;
          }
          setter(key as string, typeGuard(value[key], param));
        },
      };
    },
    [value, setter]
  );
}

export function useDateValueBinding<T extends Record<string, any>>(value: T, setter: any) {
  return useCallback(
    (key: keyof T) => {
      return {
        value: value[key] ? moment(value[key] as string) : null,
        onChange: (value: moment.Moment | null) => {
          setter(key as string, value?.startOf('day').valueOf() ?? (0 as any));
        },
      };
    },
    [value, setter]
  );
}

export function useSinglePromiseTracker() {
  const [pendings, setPendings] = useState<boolean>(false);
  const runningPromise = useRef<Promise<any> | null>(null);

  const tracer = useCallback(async <T>(promise: Promise<T>) => {
    if (runningPromise.current !== null) {
      return;
    }

    runningPromise.current = promise;
    setPendings(true);
    const [ret, err] = await to(promise);
    runningPromise.current = null;
    setPendings(false);
    if (err) {
      throw err;
    }
    return ret;
  }, []);

  return { tracer, pendings };
}

export function useSuspenseRef<T>() {
  const [loading, setLoading] = useState(true);
  const ref = useRef<T>();

  const refCallback = (v: T) => {
    ref.current = v;
    setLoading(false);
  };

  return [loading, ref, refCallback as RefCallback<T>] as const;
}

export function useNotNullableOnce(fn: () => any, judge: () => boolean) {
  const notNullable = judge();
  const triggered = useRef(false);
  const fnRef = useSyncedRef(fn);
  useEffect(() => {
    if (notNullable && !triggered.current) {
      triggered.current = true;
      fnRef.current();
    }
  }, [fnRef, notNullable]);
}
