import {Slide} from 'react-awesome-reveal';
import {BusinessCard} from '@/modules/Home/BusinessCard';
import {SkeletonLoading} from "@/components/SkeletonLoading";

export const Business = (props: any) => {
  const {BUSINESS1, BUSINESS2, BUSINESS3} = props;

  return (
    <div>
      <Slide triggerOnce>
        <SkeletonLoading loading={!BUSINESS1}>
          <BusinessCard
            position='left'
            bgUrl={BUSINESS1?.connectAttachment[0]?.connectAttachment?.content}
            logo={BUSINESS1?.connectAttachment[0]?.attachment?.content}
            isChangeSize={true}
            description={BUSINESS1?.subheading}
          />
        </SkeletonLoading>
      </Slide>

      <Slide triggerOnce>
        <SkeletonLoading loading={!BUSINESS2}>
          <BusinessCard
            position='right'
            bgUrl={BUSINESS2?.connectAttachment[0]?.connectAttachment?.content}
            logo={BUSINESS2?.connectAttachment[0]?.attachment?.content}
            isChangeSize={false}
            description={BUSINESS2?.subheading}
          />
        </SkeletonLoading>
      </Slide>

      <Slide triggerOnce>
        <SkeletonLoading loading={!BUSINESS3}>
          <BusinessCard
            position='left'
            bgUrl={BUSINESS3?.connectAttachment[0]?.connectAttachment?.content}
            logo={BUSINESS3?.connectAttachment[0]?.attachment?.content}
            isChangeSize={false}
            description={BUSINESS3?.subheading}
          />
        </SkeletonLoading>
      </Slide>
    </div>
  );
};
