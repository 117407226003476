import { Carousel, Col, Row, Tabs } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { NotRevalidateOption, usePagination } from '@web/common';
import { Page } from '@web/components';

import { getContent, getPrivateLabelChild } from '@/api/about';
import { BusinessImage } from '@/components/BusinessImage';

import './index.less';

export const PrivateBrand = () => {
  const [isShow, setIsShow] = useState(false);
  const [activeKey, setActiveKey] = useState('FOOD_RESEARCH_INSTITUTE');
  const { data: child } = useSWR(`[About]getPrivateLabelChild`, () => getPrivateLabelChild(), NotRevalidateOption);
  const { page, limit, goto } = usePagination(1, -1);
  const tabClick = (key: any) => {
    setActiveKey(key);
  };
  const params = useMemo(() => {
    return {
      page: {
        pageNumber: page,
        pageSize: limit,
      },
      requestData: {
        code: activeKey ?? '',
      },
    };
  }, [page, limit, activeKey]);

  const {
    data: content,
    mutate,
    isValidating: loading,
  } = useSWR(
    activeKey ? `[ABOUT]getContent${JSON.stringify(params)}` : null,
    () => getContent(params),
    NotRevalidateOption
  );
  /* 拿到child */
  const foodBanner = child?.find((_: any) => _.code == 'FOOD_RESEARCH_INSTITUTE');
  const brandBanner = child?.find((_: any) => _.code == 'PRIVATE_LABEL_CHILD');
  /* 拿到内容 */
  const [foodBanner1, setfoodBanner1] = useState([]);
  const [brandBanner1, setbrandBanner1] = useState([]);
  useEffect(() => {
    const food = content?.list?.filter((_: any) => _.code == 'FOOD_RESEARCH_INSTITUTE');
    const brand = content?.list?.filter((_: any) => _.code == 'PRIVATE_LABEL_CHILD');
    setfoodBanner1(food);
    setbrandBanner1(brand);
  }, [content]);

  return (
    <Page>
      {activeKey == 'FOOD_RESEARCH_INSTITUTE' ? (
        <Carousel autoplay>
          {foodBanner?.attachment.slice(0,1).map((item: any) => (
            <div >
              <BusinessImage className={'max-h-472px h-472px w-100%'} key={item.id} src={item.content} fileId={item.id} alt={item.fileName}/>
            </div>
          ))}
        </Carousel>
      ) : (
        <Carousel autoplay>
          {brandBanner?.attachment.slice(0,1).map((item: any) => (
            <div className='foodImg'>
              <BusinessImage className={'max-h-472px h-472px w-100%'} key={item.id} src={item.content} fileId={item.id} alt={item.fileName} />
            </div>
          ))}
        </Carousel>
      )}
      <div className='<md:py-7 margin-398 py-20'>
        <Tabs
          centered
          defaultActiveKey='FOOD_RESEARCH_INSTITUTE'
          size='large'
          onTabClick={(key) => tabClick(key)}
          items={[
            {
              label: <span className='text-[30px] font-bold'>{foodBanner?.title}</span>,
              key: 'FOOD_RESEARCH_INSTITUTE',
              children: (
                <div>
                  <div className={'flex justify-center'}>
                    <div
                      className='w-870px pt-36px <md:leading-60px flex
                    flex-col items-center whitespace-pre-line
                    text-justify text-[18px] font-normal leading-[32px] text-[#3d3d3d80]'
                    >
                      <span>{foodBanner?.copywriting}</span>
                    </div>
                  </div>

                  <Row gutter={[12, 12]} className='<md:py-7 flex pt-20'>
                    {foodBanner1?.slice(0, 2)?.map((item: any) => {
                      return (
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <BusinessImage
                            key={item?.attachment?.[0]?.id}
                            src={item?.attachment?.[0]?.content}
                            fileId={item?.attachment?.[0]?.id}
                            alt={item?.attachment?.[0]?.fileName}
                            className={'h-450px max-h-450px w-100%'}
                            skeletonClass={'h-450px'}
                            showLogoSkeleton={true}
                          />
                          <div className='cardThree'>
                            <div className='text-[36px] font-bold text-white'>{item?.title}</div>
                            <div className='my-[12px] h-[4px] w-[56px] bg-white' />
                            <div className='text-[24px] font-bold text-white'>{item?.content}</div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              ),
            },
            {
              label: <span className='<sm:text-[24px] text-[30px] font-bold'>{brandBanner?.title}</span>,
              key: 'PRIVATE_LABEL_CHILD',
              children: (
                <div>
                  <div className={'flex justify-center'}>
                    <div
                      className='w-870px pt-36px <md:leading-60px text-indent=36px
                   flex flex-col items-center whitespace-pre-line
                   text-justify text-[18px] font-normal leading-[32px] text-[#3d3d3d80]'
                    >
                      {brandBanner?.copywriting}
                    </div>
                  </div>
                  <Row gutter={[12, 12]} className='<md:py-70px pt-60px flex'>
                    {brandBanner1?.slice(0, 3)?.map((item: any) => {
                      return (
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <BusinessImage
                            showLogoSkeleton={true}
                            skeletonClass={'h-440px'}
                            key={item?.attachment?.[0]?.id}
                            src={item?.attachment?.[0]?.content}
                            fileId={item?.attachment?.[0]?.id}
                            alt={item?.attachment?.[0]?.fileName}
                            width={'100%'}
                            className={'h-432px max-h-432px'}
                          />
                          <div className='cardThree'>
                            <div className='text-[36px] font-bold text-white'>{item?.title}</div>
                            <div className='my-[12px] h-[4px] w-[56px] bg-white' />
                            <div className='text-[24px] font-bold text-white'>{item?.content}</div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              ),
            },
          ]}
        />
      </div>
    </Page>
  );
};
